<template>
  <div class="solved-crimes">
    <CameriteHeader />
    <CameriteJumbotron
      :main="heroMainText"
      :bottom="heroBottomText"
      img="/landing-page-media/jumbos/Topo_VC_Gov_Cases.jpg"
      mobile-img="/landing-page-media/jumbos/Img_topo_mobile_crimes_resolvidos%402x.png"
      pos="50%"
    />

    <b-container>
      <div
        class="filter d-flex flex-column flex-md-row align-items-center justify-content-md-center"
      >
        <div class="pr-2 pb-2">
          <span class="inner-menu">Filtrar por:</span>
        </div>

        <div class="pr-2 pb-2">
          <button
            v-bind:class="{ selected: filter === 'all' }"
            v-on:click="filter = 'all'"
            class="filter"
          >
            Tudo
          </button>
        </div>

        <div class="pr-2 pb-2">
          <button
            v-bind:class="{ selected: filter === 'CN' }"
            v-on:click="filter = 'CN'"
            class="filter"
          >
            Crimes Resolvidos
          </button>
        </div>

        <div class="pr-2 pb-2">
          <button
            v-bind:class="{ selected: filter === 'PS' }"
            v-on:click="filter = 'PS'"
            class="filter"
          >
            Cases de Sucesso
          </button>
        </div>
      </div>

      <b-row class="py-3">
        <div
          class="col-lg-4"
          v-on:click="$router.push('/crimes-resolvidos/1').catch(() => {})"
          v-if="filter === 'PS' || filter === 'all'"
        >
          <SolvedCrimeCard
            headline="São Paulo economiza R$ 3,6 milhões por ano"
            text="O projeto City Câmeras economiza para a cidade R$ 3,6 milhões ao ano com mais de 4.000 câmeras de vigilância."
            hyperlink-text="Saiba mais"
            hyperlink="/crimes-resolvidos/1"
          />
        </div>
        <div
          class="col-lg-4"
          v-on:click="$router.push('/crimes-resolvidos/3').catch(() => {})"
          v-if="filter === 'PS' || filter === 'all'"
        >
          <SolvedCrimeCard
            headline="A cidade mais monitorada da América Latina"
            text="A cidade de Palotina, no interior do Paraná, diminuiu em 80% o índice de criminalidade."
            hyperlink-text="Saiba mais"
            hyperlink="/crimes-resolvidos/3"
          />
        </div>
        <div
          class="col-lg-4"
          v-on:click="$router.push('/crimes-resolvidos/4').catch(() => {})"
          v-if="filter === 'PS' || filter === 'all'"
        >
          <SolvedCrimeCard
            headline="São Gabriel/RS diminui o índice de crimes"
            text="Câmeras de segurança auxiliam os órgãos de segurança pública a elucidar furtos e roubos."
            hyperlink-text="Saiba mais"
            hyperlink="/crimes-resolvidos/4"
          />
        </div>
        <div
          class="col-lg-4"
          v-on:click="$router.push('/crimes-resolvidos/2').catch(() => {})"
          v-if="filter === 'CN' || filter === 'all'"
        >
          <SolvedCrimeCard
            headline="Vizinhos colaboram com a polícia em Cacoal/RO"
            text="Motocicleta furtada é recuperada em ação policial registrada pelas câmeras da Vizinhança Colaborativa."
            hyperlink-text="Saiba mais"
            hyperlink="/crimes-resolvidos/2"
          />
        </div>
      </b-row>

      <SeeHowEasyItIs />
    </b-container>

    <CameriteFooter />
  </div>
</template>

<script>
import CameriteHeader from "../components/camerite-header/CameriteHeader";
import CameriteJumbotron from "../components/CameriteJumbotron";
import SeeHowEasyItIs from "../components/SeeHowEasyItIs";
import CameriteFooter from "../components/CameriteFooter";
import SolvedCrimeCard from "../components/SolvedCrimeCard";

export default {
  name: "Functionalities",
  components: {
    CameriteHeader,
    CameriteJumbotron,
    SeeHowEasyItIs,
    CameriteFooter,
    SolvedCrimeCard,
  },
  data() {
    return {
      heroMainText: "A população fica mais protegida com a Camerite",
      heroBottomText:
        "De norte a sul do Brasil, a Vizinhança Colaborativa contribui não apenas para a prevenção como é também um grande recurso para resolução de crimes.",
      filter: "all",
    };
  },
};
</script>
