<template>
    <b-modal ref="msgSentModal" id="msg-sent-modal" hide-footer hide-header dialog-class="msg-sent-modal">
        <div class="d-flex flex-column align-items-center justify-content-center p-3">
            <IconMessageSent/>
            <h3 class="mt-4 text-center">Mensagem enviada com sucesso!</h3>
            <p class="mt-2">Obrigado por entrar em contato.</p>
            <button class="tangerine w-100 mt-3" v-on:click="close">Fechar
            </button>
        </div>
    </b-modal>
</template>

<script>
    import IconMessageSent from "../components/icons/IconMessageSent";

    export default {
        name: "MessageSentModal",
        components: {
            IconMessageSent
        },
        methods: {
            show() {
                this.$bvModal.show('msg-sent-modal');
            },
            close() {
                this.$bvModal.hide('msg-sent-modal');
                this.$emit('modalDestroy');
            }
        }
    }
</script>

<style scoped lang="scss">
    .msg-sent-modal {
        max-width: 348px;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);

        .modal-body {
            width: 348px;

            button {
                max-width: 162px !important;
            }
        }
    }
</style>
