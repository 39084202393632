<template>
    <div class="solved-crime">
        <h1 class="pt-5 pb-2">Palotina/PR</h1>
        <p class="support-text pb-4">
            A cidade de Palotina no Paraná faz parte das cidades inteligentes Camerite e tornou-se o município mais
            monitorado da América Latina, reduzindo os índices de criminalidade em 80%.
        </p>

        <p class="testimony pb-2">
            “Antes, não tínhamos nenhuma câmera de vigilância compartilhada com os órgãos de segurança pública.”
        </p>
        <p class="bottom-testimony">
            Jucenir Stentzler
        </p>
        <p class="bottom-testimony pb-4">
            Prefeito da cidade de Palotina/PR
        </p>

        <h3 class="pb-3">Palotina hoje</h3>

        <div class="d-flex flex-row pb-3">
            <IconCheck />
            <span class="pl-3">420 câmeras de vigilância</span>
        </div>
        <div class="d-flex flex-row pb-3">
            <IconCheck />
            <span class="pl-3">25 câmeras de vigilância com recurso OCR de leitura de placas</span>
        </div>
        <div class="d-flex flex-row pb-4">
            <IconCheck />
            <span class="pl-3">1 câmera de vigilância para cada 66 habitantes</span>
        </div>

        <h3 class="pb-3">Segurança na prática</h3>
        <div class="pb-5">
            <youtube-player id="4oTZMrIo6sQ"></youtube-player>
        </div>
    </div>
</template>

<script>
    import IconCheck from "../components/icons/IconCheck";
    import YoutubePlayer from "@/components/YoutubePlayer";

    export default {
        name: "SC3",
        components: {
            IconCheck,
            YoutubePlayer
        }
    }
</script>

<style scoped lang="scss">
    p {
        margin-bottom: unset;
    }

    p.testimony {
        font-size: 18px;
        color: $camerite-blue;
    }

    p.bottom-testimony, span.bottom-testimony {
        font-size: 14px;
        font-weight: bold;
    }
</style>
