<template>
    <div class="vertical-separator" v-bind:style="style"></div>
</template>

<script>
    export default {
        name: "VerticalSeparator",
        props: {
            width: Number,
            center: Boolean
        },
        data() {
            const style = {'width': this.width + 'px'};

            if (this.center) {
                style['left'] = '50%';
            }

            return {
                style: style
            }
        }
    }
</script>

<style scoped lang="scss">
    div.vertical-separator {
        height: 100%;
        position: absolute;
        background-color: #BDBDBD;
    }
</style>
