<template>
  <div class="functionalities">
    <CameriteHeader />
    <CameriteJumbotron
      :top="heroTopText"
      :main="heroMainText"
      :bottom="heroBottomText"
      img="/landing-page-media/jumbos/Topo_VC_Funcionalidades.jpg"
      mobile-img="/landing-page-media/jumbos/Img_topo_mobile_funcionalidades%402x.png"
      pos="50%"
    />

    <b-container>
      <b-row class="py-5">
        <div class="offset-lg-1 col-lg-5 order-lg-first order-last">
          <b-img
            src="/landing-page-media/collaborative-neighborhood/App_Android_timeline2.png"
            fluid
          ></b-img>
        </div>

        <div
          class="offset-lg-1 col-lg-5 d-flex justify-content-center flex-column"
        >
          <div class="d-flex flex-column flex-row">
            <div class="pr-3 pr-lg-0">
              <IconTimeline />
            </div>

            <div class="py-2">
              <h2>Linha do tempo</h2>
            </div>
          </div>

          <div>
            <p>
              Com a linha do tempo você pode avançar e retroceder nas gravações
              de suas câmeras de segurança de forma fácil e rápida. Você nunca
              mais ficará sem saber o que acontece em sua rua!
            </p>
            <p>
              Um recurso muito útil, para visualizar desde a circulação de um
              suspeito ou mesmo identificar a direção que seu animalzinho fujão
              tomou ao sair de casa!
            </p>
          </div>
        </div>
      </b-row>

      <b-row class="py-5">
        <div class="col-lg-5 d-flex justify-content-center flex-column">
          <div class="d-flex flex-column flex-row">
            <div class="pr-3 pr-lg-0">
              <IconAlert />
            </div>

            <div class="py-2">
              <h2>Botão de alerta</h2>
            </div>
          </div>

          <div>
            <p>
              Ao ver uma situação incomum em suas câmeras de segurança, você
              pode emitir um alerta para todos os seus vizinhos através de um
              botão no aplicativo. Assim, todos podem estar atentos à presença
              de suspeitos na rua.
            </p>
            <p>
              Quanto maior o número de vizinhos engajados com o monitoramento,
              mais efetivo é o monitoramento, quanto mais cooperatividade mais
              segurança para você e sua família!
            </p>
          </div>
        </div>

        <div class="offset-lg-1 col-lg-5">
          <b-img
            src="/landing-page-media/collaborative-neighborhood/App_Android_alerta2.png"
            fluid
          ></b-img>
        </div>
      </b-row>

      <b-row class="py-5">
        <div class="offset-lg-1 col-lg-5 order-lg-first order-last">
          <b-img
            src="/landing-page-media/collaborative-neighborhood/Mobile-site-chat%402x.png"
            fluid
          ></b-img>
        </div>

        <div
          class="offset-lg-1 col-lg-5 d-flex justify-content-center flex-column"
        >
          <div
            class="d-flex flex-column flex-row align-content-center align-content-lg-start"
          >
            <div class="pr-3 pr-lg-0">
              <IconChat />
            </div>

            <div class="py-2">
              <h2>Chat</h2>
            </div>
          </div>

          <div>
            <p>
              Um recurso exclusivo para troca de informações entre vizinhos.
              Depois de disparar o botão de alerta, por exemplo, você pode
              informar seus vizinhos através do chat que situação suspeita você
              identificou através de suas câmeras de segurança.
            </p>
          </div>
        </div>
      </b-row>

      <b-row class="py-5">
        <div class="col-lg-5 d-flex justify-content-center flex-column">
          <div
            class="d-flex flex-column flex-row align-content-center align-content-lg-start"
          >
            <div class="pr-3 pr-lg-0">
              <IconSafeStorage />
            </div>

            <div class="py-2">
              <h2>Armazenamento on-line</h2>
            </div>
          </div>

          <div>
            <p>
              Todas as imagens são armazenadas 100% online, ou seja, mesmo que a
              câmera seja furtada ou danificada, as gravações permanecem seguras
              e podem ser utilizadas normalmente.
            </p>

            <p>
              Assim, os criminosos podem ser identificados e você colabora para
              uma sociedade mais segura para você e para sua família.
            </p>
          </div>
        </div>

        <div class="offset-lg-1 col-lg-5">
          <b-img
            src="/landing-page-media/collaborative-neighborhood/App_Android_video2.png"
            fluid
          ></b-img>
        </div>
      </b-row>

      <b-row class="py-5">
        <div class="offset-lg-1 col-lg-5 order-last order-lg-first">
          <b-img
            src="/landing-page-media/collaborative-neighborhood/App_Android_salvar_video2.png"
            fluid
          ></b-img>
        </div>

        <div
          class="offset-lg-1 col-lg-5 d-flex justify-content-center flex-column"
        >
          <div
            class="d-flex flex-column flex-row align-content-center align-content-lg-start"
          >
            <div class="pr-3 pr-lg-0">
              <IconDownload />
            </div>

            <div class="py-2">
              <h2>Download de gravações</h2>
            </div>
          </div>

          <div>
            <p>
              Precisa compartilhar um momento da gravação? Com o aplicativo
              Camerite, você pode baixar trechos das gravações de suas câmeras
              de segurança diretamente em seu celular de forma fácil e rápida!
            </p>
          </div>
        </div>
      </b-row>

      <SeeHowEasyItIs />
    </b-container>

    <CameriteFooter />
  </div>
</template>

<script>
import CameriteHeader from "../components/camerite-header/CameriteHeader";
import CameriteJumbotron from "../components/CameriteJumbotron";
import IconTimeline from "../components/icons/IconTimeline";
import IconAlert from "../components/icons/IconAlert";
import IconChat from "../components/icons/IconChat";
import IconSafeStorage from "../components/icons/IconSafeStorage";
import IconDownload from "../components/icons/IconDownload";
import SeeHowEasyItIs from "../components/SeeHowEasyItIs";
import CameriteFooter from "../components/CameriteFooter";

export default {
  name: "Functionalities",
  components: {
    CameriteHeader,
    CameriteJumbotron,
    IconTimeline,
    IconAlert,
    IconChat,
    IconSafeStorage,
    IconDownload,
    SeeHowEasyItIs,
    CameriteFooter,
  },
  data() {
    return {
      heroTopText: "A segurança na palma da sua mão",
      heroMainText: "Uma solução simples para quem quer viver mais tranquilo.",
      heroBottomText:
        "Diferente de outros sistemas difíceis de manusear, o aplicativo Camerite é pensado para o dia a dia, para que toda e qualquer pessoa possa utilizar com facilidade, mesmo sem afinidade com tecnologia. Com ele, todos têm acesso à segurança!",
    };
  },
};
</script>
