<template>
  <div class="police">
    <CameriteHeader />
    <CameriteJumbotron
      :top="heroTopText"
      :main="heroMainText"
      :bottom="heroBottomText"
      img="/landing-page-media/jumbos/Topo_Gov_Principal.jpg"
      mobile-img="/landing-page-media/jumbos/Img_topo_mobile_governos%402x.png"
      pos="50%"
    />

    <b-container class="pt-4">
      <b-row class="pb-5">
        <div class="col-lg-5 py-sm-3">
          <div class="pb-3">
            <h2>Problemas das soluções tradicionais</h2>
          </div>

          <div class="pb-3">
            <p>
              Uma série de dificuldades acabam por tornar lento e pouco
              escalável a implantação de um cinturão de câmeras de vigilância
              que efetivamente atendam às necessidades dos órgãos de segurança
              pública.
            </p>
          </div>

          <div>
            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Soluções isoladas</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Alto custo para agregar tecnologia</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Risco de perda de imagens</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Tempo de implantação elevado</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Alto investimento em infraestrutura local</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Dificuldade em manutenção dos equipamentos</span>
              </div>
            </b-row>
          </div>
        </div>

        <div class="col-lg-2 separator">
          <VerticalSeparator :width="1" :center="true" />
        </div>

        <div class="col-lg-5 py-sm-3">
          <div class="pb-3">
            <h2>Camerite, uma solução completa</h2>
          </div>

          <div class="pb-3">
            <p>
              Tendo em vista as principais dificuldades que envolvem a segurança
              pública, a Camerite em parceria com a Microsoft oferece uma
              solução completa de tecnologia em segurança e videomonitoramento
              de cidades.
            </p>
          </div>

          <div>
            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconSafeStorage />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Plataforma de armazenamento 100% em nuvem</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconNoServer />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Dispensa servidores locais e infraestrutura pesada</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconLPR />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Sistema de leitura de placas</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconHorus />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Sistema de análise de rotas de veículos e pessoas</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-4">
              <div class="pr-2">
                <IconApp />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Acesso via aplicativos para agentes em campo</span>
              </div>
            </b-row>
          </div>
        </div>
      </b-row>
    </b-container>

    <b-nav class="sticky-carousel justify-content-center">
      <b-row>
        <div
          class="col-4 carousel-selector px-md-5"
          v-on:click="scrollTo(carouselOcr)"
        >
          <div class="py-0 py-md-2 text-center">
            <IconLPR />
          </div>

          <div class="pt-1 text-center">
            <span
              class="inner-menu text-nowrap"
              v-bind:class="{ 'carousel-selected': carouselSelected === 'ocr' }"
            >
              {{ mobileView ? "OCR" : "OCR - Leitura de Placas" }}
            </span>
          </div>
        </div>

        <div
          class="col-4 carousel-selector px-md-5"
          v-on:click="scrollTo(carouselCortex)"
        >
          <div class="py-0 py-md-2 text-center">
            <IconCortex />
          </div>

          <div class="pt-1 text-center">
            <span
              class="inner-menu text-nowrap"
              v-bind:class="{
                'carousel-selected': carouselSelected === 'cortex',
              }"
            >
              {{ mobileView ? "Cortex" : "Mapa Investigativo Cortex" }}
            </span>
          </div>
        </div>

        <div
          class="col-4 carousel-selector px-md-5"
          v-on:click="scrollTo(carouselHorus)"
        >
          <div class="py-0 py-md-2 text-center">
            <IconHorus />
          </div>

          <div class="pt-1 text-center">
            <span
              class="inner-menu text-nowrap"
              v-bind:class="{
                'carousel-selected': carouselSelected === 'horus',
              }"
            >
              {{ mobileView ? "Hórus" : "Análise de Rotas Hórus" }}
            </span>
          </div>
        </div>
      </b-row>
    </b-nav>

    <div
      class="ocr-stick py-5"
      key="ocr"
      v-bind:style="{ 'scroll-margin': this.stickyScrollMargin + 'px' }"
    >
      <b-container>
        <h2 class="text-center pt-4 pb-3">Câmeras OCR Leitura de Placas</h2>

        <p class="offset-lg-2 col-lg-8 text-center">
          Uma ferramenta inteligente de reconhecimento de placas de veículos,
          simples de operar e ainda mais simples de implantar. 100% online,
          dispensa infraestrutura pesada.
        </p>

        <b-row align-v="center" class="carousel-ocr-row">
          <div
            class="col-lg-5 pt-3 d-lg-flex flex-lg-column justify-content-lg-between carousel-ocr"
          >
            <b-carousel
              class="pb-4"
              v-model="cnCarouselSlideOcr"
              ref="cnCarouselOcr"
              :interval="0"
            >
              <div class="carousel-item active">
                <div class="pb-4">
                  <h3>Processamento em tempo real</h3>
                </div>

                <div>
                  <p>
                    Identificar uma placa veicular de forma manual custa caro e
                    leva tempo, e na investigação tempo é fundamental.
                  </p>
                  <p>
                    O sistema de leitura de placas Camerite faz o processamento
                    das imagens em tempo real, entregando uma lista atualizada
                    das placas identificadas e ainda permite filtros por data,
                    horário, tornando muito mais rápido a busca por um veículo e
                    a identificação de rotas.
                  </p>
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Identifica placas nacionais e internacionais</h3>
                </div>

                <div>
                  <p>
                    Monitore veículos nacionais e internacionais, em pontos
                    estratégicos, desde pequenos bairros até áreas urbanas de
                    grande circulação.
                  </p>
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Emite alertas de placas de interesse</h3>
                </div>

                <div>
                  <p>
                    Crie blacklists registrando placas de veículos de interesse
                    e seja notificado via web e aplicativos para smartphone toda
                    vez que uma placa suspeita for lida pelo sistema.
                  </p>
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Consulta de imagem</h3>
                </div>

                <div>
                  <p>
                    Visualize a imagem do veículo no momento da leitura de forma
                    rápida e fácil.
                  </p>
                  <p>
                    Além de confirmar a placa lida este recurso pode ser útil
                    para reconhecer o local, condições do veículo e sentido de
                    deslocamento.
                  </p>
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Histórico de notificações no aplicativo</h3>
                </div>

                <div>
                  <p>
                    Além da emissão de alertas em tempo real, o sistema também
                    registra cada notificação com especificação de data, hora e
                    local, permitindo uma consulta posterior ao ocorrido com
                    grande facilidade.
                  </p>
                </div>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="!mobileView">
              <ArrowButton
                class="mx-2"
                direction="left"
                :action="previousSlideOcr"
              ></ArrowButton>
              <ArrowButton
                class="mx-2"
                direction="right"
                :action="nextSlideOcr"
              ></ArrowButton>
            </div>
          </div>

          <div class="col-lg-7 pt-2 h-100">
            <b-carousel
              class="pb-4 h-100"
              v-model="cnCarouselSlideImgOcr"
              ref="cnCarouselImgOcr"
              :interval="0"
            >
              <div class="carousel-item img active h-100">
                <b-img
                  src="/landing-page-media/policia/LPR_lista%402x.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/LPR_placas.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/LPR_alerta%402x.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/LPR_info_carro%402x.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  class="h-100"
                  src="/landing-page-media/policia/LPR_Alerta_historico_Android%402x.png"
                  fluid
                >
                </b-img>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="mobileView">
              <ArrowButton
                class="mx-2"
                direction="left"
                :action="previousSlideOcr"
              ></ArrowButton>
              <ArrowButton
                class="mx-2"
                direction="right"
                :action="nextSlideOcr"
              ></ArrowButton>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>

    <div
      class="cortex-film py-5 cortex-stick"
      key="cortex"
      v-bind:style="{ 'scroll-margin': this.stickyScrollMargin + 'px' }"
    >
      <b-container>
        <h2 class="revert-text text-center pt-4 pb-3">
          Mapa Investigativo Cortex
        </h2>

        <p class="revert-text offset-lg-2 col-lg-8 text-center">
          Para velocidade em sua operação, utilize o Cortex, o mapa
          investigativo da Camerite. Gerencie suas câmeras de segurança de forma
          simples e eficiente.
        </p>

        <b-row align-v="center" class="carousel-cortex-row">
          <div
            class="col-lg-5 pt-3 carousel-cortex d-lg-flex flex-lg-column justify-content-lg-between carousel-horus"
          >
            <b-carousel
              class="pb-4"
              v-model="cnCarouselSlideCortex"
              ref="cnCarouselCortex"
              :interval="0"
            >
              <div class="carousel-item active">
                <div class="pb-4">
                  <h3 class="revert-text">Busca por endereço</h3>
                </div>

                <div>
                  <p class="revert-text">
                    Com um interface simples de operar, o investigador pode
                    buscar através do mapa um endereço específico, identificar
                    todas as câmeras disponíveis na região e o ângulo de
                    cobertura de cada câmera.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay></IconPlay>
                  <span class="pl-2 underline revert-text"
                    >Mais sobre o Cortex</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3 class="revert-text">Crie mosaicos rapidamente</h3>
                </div>

                <div>
                  <p class="revert-text">
                    Com apenas um clique, o investigador pode criar mosaicos com
                    múltiplas câmeras rapidamente. Este recurso é muito útil na
                    identificação de rotas, permitindo monitorar o trajeto de um
                    suspeito através das câmeras locais.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay></IconPlay>
                  <span class="pl-2 underline revert-text"
                    >Mais sobre o Cortex</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3 class="revert-text">
                    Faça o reconhecimento da área utilizando o recurso Street
                    View
                  </h3>
                </div>

                <div>
                  <p class="revert-text">
                    O sistema ainda permite o uso do recurso Google Street View,
                    que permite a visão de solo da rua para identificação do
                    terreno e da localização das câmeras.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay></IconPlay>
                  <span class="pl-2 underline revert-text"
                    >Mais sobre o Cortex</span
                  >
                </div>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="!mobileView">
              <ArrowButton
                :revert="true"
                class="mx-2"
                direction="left"
                :action="previousSlideCortex"
              ></ArrowButton>
              <ArrowButton
                :revert="true"
                class="mx-2"
                direction="right"
                :action="nextSlideCortex"
              ></ArrowButton>
            </div>
          </div>

          <div class="col-lg-7 pt-2">
            <b-carousel
              class="pb-4"
              v-model="cnCarouselSlideImgCortex"
              ref="cnCarouselImgCortex"
              :interval="0"
            >
              <div class="carousel-item active">
                <b-img
                  src="/landing-page-media/policia/Cortex_home%402x.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item">
                <b-img
                  src="/landing-page-media/policia/Cortex_mosaicos%402x.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item">
                <b-img
                  src="/landing-page-media/policia/Cortex_street_view%402x.png"
                  fluid
                ></b-img>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="mobileView">
              <ArrowButton
                :revert="true"
                class="mx-2"
                direction="left"
                :action="previousSlideCortex"
              ></ArrowButton>
              <ArrowButton
                :revert="true"
                class="mx-2"
                direction="right"
                :action="nextSlideCortex"
              ></ArrowButton>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>

    <div
      class="py-5 horus-stick"
      key="horus"
      v-bind:style="{ 'scroll-margin': this.stickyScrollMargin + 'px' }"
    >
      <b-container>
        <h2 class="text-center pt-4 pb-3">Análise de Rotas Hórus</h2>

        <p class="offset-lg-2 col-lg-8 text-center">
          Tecnologia a favor da segurança pública. Faça análises investigativas
          com o Hórus Camerite, capaz de identificar rotas através de
          inteligência artificial.
        </p>

        <b-row align-v="center" class="carousel-horus-row">
          <div
            class="col-lg-5 order-lg-last pt-3 d-lg-flex flex-lg-column justify-content-lg-between carousel-horus"
          >
            <b-carousel
              class="pb-4"
              v-model="cnCarouselSlideHorus"
              ref="cnCarouselHorus"
              :interval="0"
            >
              <div class="carousel-item active">
                <div class="pb-4">
                  <h3>Selecione as câmeras de segurança</h3>
                </div>

                <div>
                  <p>
                    Com um interface simples de operar, o investigador pode
                    buscar através do mapa um endereço específico, identificar
                    todas as câmeras de segurança disponíveis na região e o
                    ângulo de cobertura de cada câmera.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay color="#f38e1c"></IconPlay>
                  <span class="pl-2 underline tangerine-text"
                    >Mais sobre o Hórus</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Defina o objeto de busca</h3>
                </div>

                <div>
                  <p>
                    O investigador seleciona o objeto para busca que deseja
                    encontrar nas imagens através de inteligência artificial. Os
                    objetos podem ser pessoas, carros, bicicletas ou
                    motocicletas.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay color="#f38e1c"></IconPlay>
                  <span class="pl-2 underline tangerine-text"
                    >Mais sobre o Hórus</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Utilize o filtro de cores</h3>
                </div>

                <div>
                  <p>
                    Também é possível aplicar o filtro de cor para cores
                    predominantes, selecionando a cor vermelha para buscar um
                    carro vermelho, por exemplo.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay color="#f38e1c"></IconPlay>
                  <span class="pl-2 underline tangerine-text"
                    >Mais sobre o Hórus</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Defina a janela de tempo</h3>
                </div>

                <div>
                  <p>
                    Através do calendário, o investigador pode selecionar o
                    período de busca, filtrando resultados de uma janela de
                    tempo específica.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay color="#f38e1c"></IconPlay>
                  <span class="pl-2 underline tangerine-text"
                    >Mais sobre o Hórus</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Selecione os eventos correspondentes</h3>
                </div>

                <div>
                  <p>
                    Neste momento a inteligência artificial irá apresentar todos
                    os resultados correspondentes às informações definidas pelo
                    investigador. Agora basta identificar e selecionar as
                    imagens desejadas.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay color="#f38e1c"></IconPlay>
                  <span class="pl-2 underline tangerine-text"
                    >Mais sobre o Hórus</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Descubra a rota do objeto</h3>
                </div>

                <div>
                  <p>
                    Depois de selecionadas as imagens correspondentes à busca, a
                    inteligência artificial irá apresentar a possível rota do
                    objeto, desde o ponto de partida até o último registro feito
                    pelas câmeras de segurança, entregando ao investigador
                    informações de extrema importância para a localização do
                    objeto.
                  </p>
                </div>

                <div
                  class="clickable"
                  v-on:click="
                    open('https://www.youtube.com/watch?v=jJ6cnPgeLuA')
                  "
                >
                  <IconPlay color="#f38e1c"></IconPlay>
                  <span class="pl-2 underline tangerine-text"
                    >Mais sobre o Hórus</span
                  >
                </div>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="!mobileView">
              <ArrowButton
                class="mx-2"
                direction="left"
                :action="previousSlideHorus"
              ></ArrowButton>
              <ArrowButton
                class="mx-2"
                direction="right"
                :action="nextSlideHorus"
              ></ArrowButton>
            </div>
          </div>

          <div class="col-lg-7 order-lg-first pt-2 h-100">
            <b-carousel
              class="pb-4 h-100"
              v-model="cnCarouselSlideImgHorus"
              ref="cnCarouselImgHorus"
              :interval="0"
            >
              <div class="carousel-item img active h-100">
                <b-img
                  src="/landing-page-media/policia/Horus_home%402x.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/Horus_objeto.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/Horus_filtro.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/Horus_janela.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/Horus_lista_resultados%402x.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item img h-100">
                <b-img
                  src="/landing-page-media/policia/Horus_rota_mapa%402x.png"
                  fluid
                ></b-img>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="mobileView">
              <ArrowButton
                class="mx-2"
                direction="left"
                :action="previousSlideHorus"
              ></ArrowButton>
              <ArrowButton
                class="mx-2"
                direction="right"
                :action="nextSlideHorus"
              ></ArrowButton>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>

    <b-container>
      <SeeHowEasyItIs text="smart-cities" />
    </b-container>

    <CameriteFooter />
  </div>
</template>

<script>
import ArrowButton from "../components/ArrowButton";
import CameriteHeader from "../components/camerite-header/CameriteHeader.vue";
import CameriteJumbotron from "../components/CameriteJumbotron";
import VerticalSeparator from "../components/VerticalSeparator";
import IconAttention from "../components/icons/IconAttention";
import SeeHowEasyItIs from "../components/SeeHowEasyItIs";
import CameriteFooter from "../components/CameriteFooter";
import IconSafeStorage from "../components/icons/IconSafeStorage";
import IconNoServer from "../components/icons/IconNoServer";
import IconLPR from "../components/icons/IconLPR";
import IconHorus from "../components/icons/IconHorus";
import IconApp from "../components/icons/IconApp";
import IconCortex from "../components/icons/IconCortex";
import IconPlay from "../components/icons/IconPlay";

export default {
  name: "CollaborativeNeighbourhood",
  components: {
    ArrowButton,
    CameriteHeader,
    CameriteJumbotron,
    VerticalSeparator,
    IconAttention,
    SeeHowEasyItIs,
    CameriteFooter,
    IconSafeStorage,
    IconNoServer,
    IconLPR,
    IconHorus,
    IconApp,
    IconCortex,
    IconPlay,
  },
  data() {
    return {
      heroTopText: "Cidades Inteligentes",
      heroMainText: "Tecnologia em segurança para Cidades Inteligentes",
      heroBottomText:
        "Fornecemos tecnologia e inteligência artificial para que você entregue mais segurança para sua cidade através de câmeras de vigilância inteligentes.",
      cnCarouselSlideOcr: 0,
      cnCarouselSlideImgOcr: 0,
      cnCarouselSlideFaceRecog: 0,
      cnCarouselSlideImgFaceRecog: 0,
      cnCarouselSlideCortex: 0,
      cnCarouselSlideImgCortex: 0,
      cnCarouselSlideHorus: 0,
      cnCarouselSlideImgHorus: 0,
      carouselSelected: "",
      carouselOcr: null,
      carouselFaceRecog: null,
      carouselCortex: null,
      carouselHorus: null,
      mobileView: window.innerWidth <= 991,
      stickyScrollMargin: 0,
    };
  },
  methods: {
    open: function (href) {
      window.open(href, "_blank");
    },
    previousSlideOcr() {
      this.$refs.cnCarouselOcr.prev();
      this.$refs.cnCarouselImgOcr.prev();
    },
    nextSlideOcr() {
      this.$refs.cnCarouselOcr.next();
      this.$refs.cnCarouselImgOcr.next();
    },
    previousSlideFR() {
      this.$refs.cnCarouselFR.prev();
      this.$refs.cnCarouselImgFR.prev();
    },
    nextSlideFR() {
      this.$refs.cnCarouselFR.next();
      this.$refs.cnCarouselImgFR.next();
    },
    previousSlideCortex() {
      this.$refs.cnCarouselCortex.prev();
      this.$refs.cnCarouselImgCortex.prev();
    },
    nextSlideCortex() {
      this.$refs.cnCarouselCortex.next();
      this.$refs.cnCarouselImgCortex.next();
    },
    previousSlideHorus() {
      this.$refs.cnCarouselHorus.prev();
      this.$refs.cnCarouselImgHorus.prev();
    },
    nextSlideHorus() {
      this.$refs.cnCarouselHorus.next();
      this.$refs.cnCarouselImgHorus.next();
    },
    handleScroll() {
      const ocrY = this.carouselOcr.getBoundingClientRect().y;
      const frY = this.carouselFaceRecog.getBoundingClientRect().y;
      const cortexY = this.carouselCortex.getBoundingClientRect().y;
      const horusY = this.carouselHorus.getBoundingClientRect().y;

      if (ocrY <= 100 && ocrY >= -this.carouselOcr.offsetHeight + 100) {
        this.carouselSelected = "ocr";
      } else if (
        frY <= 100 &&
        frY >= -this.carouselFaceRecog.offsetHeight + 100
      ) {
        this.carouselSelected = "faceRecog";
      } else if (
        cortexY <= 100 &&
        cortexY >= -this.carouselCortex.offsetHeight + 100
      ) {
        this.carouselSelected = "cortex";
      } else if (
        horusY <= 100 &&
        horusY >= -this.carouselHorus.offsetHeight + 100
      ) {
        this.carouselSelected = "horus";
      } else {
        this.carouselSelected = "";
      }
    },
    scrollTo(el) {
      el.scrollIntoView({ behavior: "smooth" });
    },
    handleView: function () {
      this.mobileView = window.innerWidth <= 991;
      this.stickyScrollMargin =
        this.$el.getElementsByClassName("sticky-carousel")[0].offsetHeight;
    },
  },
  mounted() {
    this.carouselOcr = this.$el.getElementsByClassName("ocr-stick")[0];
    this.carouselOcrMilestone =
      this.$el.getElementsByClassName("ocr-milestone")[0];
    this.carouselFaceRecog =
      this.$el.getElementsByClassName("face-recog-stick")[0];
    this.carouselCortex = this.$el.getElementsByClassName("cortex-stick")[0];
    this.carouselHorus = this.$el.getElementsByClassName("horus-stick")[0];
    this.stickyScrollMargin =
      this.$el.getElementsByClassName("sticky-carousel")[0].offsetHeight;
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleView);
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleScroll);
  },
};
</script>

<style lang="scss">
div.carousel-selector {
  cursor: pointer;
  border-bottom: 4px solid transparent;

  span {
    border-bottom: 4px solid transparent;
    padding-bottom: 2px;
    transition: 0.3s;

    &.carousel-selected {
      border-bottom: 4px solid $camerite-blue;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

div.cortex-film {
  background-color: $camerite-blue;
}

.sticky-carousel {
  top: 0;
  position: sticky;
  position: -webkit-sticky;
  z-index: 5;
  background-color: white;
  border-bottom: 1px solid #cccccc;
}

div.ocr-stick,
div.horus-stick {
  background-color: $camerite-psycho-killer;
}

@media (min-width: 991px) {
  .carousel-item.img.active,
  .carousel-item-prev.img,
  .carousel-item-next.img {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .carousel-inner {
    height: 100%;
  }

  div.carousel-ocr-row {
    height: 676px;

    div.carousel-ocr {
      height: 314px;
    }
  }
  div.carousel-fr-row {
    div.carousel-fr {
      height: 267px;
    }
  }
  div.carousel-cortex-row {
    div.carousel-cortex {
      height: 262px;
    }
  }
  div.carousel-horus-row {
    height: 645px;

    div.carousel-horus {
      height: 300px;
    }
  }
}
</style>
