<template>
  <div class="web-header">
    <b-container>
      <b-row class="align-items-center">
        <div
          class="camerite-logo col-2 h-100"
          v-on:click="$router.push('/').catch(() => {})"
        >
          <b-img
            class="w-100"
            src="/landing-page-media/header/logo_pin.png"
          ></b-img>
        </div>

        <div class="col-10">
          <div class="d-flex justify-content-end">
            <div class="d-flex flex-row align-items-center pr-4">
              <div
                class="menu-route px-2"
                v-for="route in routes"
                :key="route.path"
              >
                <div
                  class="d-flex justify-content-center"
                  v-on:click="$router.push(route.path).catch(() => {})"
                >
                  <span class="inner-menu">{{ route.name }}</span>
                </div>
              </div>
            </div>

            <div class="pick-a-camerite">
              <button
                class="bluerite-secondary float-right"
                v-on:click="$router.push('/encontre').catch(() => {})"
              >
                Encontre uma Camerite
              </button>
            </div>

            <div class="ml-lg-4">
              <button
                class="secondary-tangerine"
                v-on:click="
                  open('https://contato.camerite.com/seja-um-franqueado')
                "
              >
                Seja um franqueado
              </button>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "WebHeader",
  props: {
    routes: Array,
  },
  methods: {
    open: function (href) {
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
div.web-header {
  padding: 14px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 2px;

  div.camerite-logo {
    cursor: pointer;
  }

  div.menu-route {
    transition: 0.2s;
    cursor: pointer;

    span {
      font-size: 15px;
      white-space: nowrap;
      transition: 0.2s;
    }

    &:hover span {
      color: $camerite-blue-hover;
    }
  }

  div.pick-a-camerite {
    button {
      font-size: 14px;
      width: 186px;
    }
  }

  button.secondary-tangerine {
    width: 162px;
  }
}
</style>
