<template>
    <div class="talk-to-us">
        <CameriteHeader/>

        <b-container>
            <div class="offset-lg-2 col-lg-8 py-lg-5 pt-3 pb-4">
                <div class="py-2">
                    <h2>Fale com a gente</h2>
                </div>

                <div>
                    <span>Entre em contato com a Camerite para tirar suas dúvidas.</span>
                </div>
            </div>

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                    <b-row class="pb-lg-3">
                        <div class="offset-lg-2 col-lg-3">
                            <validation-provider
                                name="Nome"
                                :rules="{ required: true}"
                                v-slot="validationContext">
                                <b-form-group id="input-name" label="Nome*" label-for="input-name">
                                    <b-form-input
                                        v-model="form.name"
                                        :state="getValidationState(validationContext) && null">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-name">{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div class="col-lg-3">
                            <validation-provider
                                name="Email"
                                :rules="{ required: true, email: true}"
                                v-slot="validationContext">
                                <b-form-group id="input-email" label="E-mail*" label-for="input-email">
                                    <b-form-input
                                        v-model="form.email"
                                        :state="getValidationState(validationContext) && null">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-email">{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div class="col-lg-2">
                            <validation-provider
                                name="Phone"
                                :rules="{ required: true}"
                                v-slot="validationContext">
                                <b-form-group id="input-phone" label="Telefone*" label-for="input-phone">
                                    <b-form-input
                                        v-model="form.phone"
                                        v-mask="'(99) 99999-9999'"
                                        :state="getValidationState(validationContext) && null">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-email">{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>
                    </b-row>

                    <b-row class="pb-lg-3">
                        <div class="offset-lg-2 col-lg-1">
                            <validation-provider
                                name="State"
                                :rules="{ required: true}"
                                v-slot="validationContext">
                                <b-form-group id="input-state" label="Estado*" label-for="input-state">
                                    <v-select
                                        class="camerite-dropdown"
                                        v-model="form.state"
                                        :options="stateOptions"
                                        label="initials">
                                        <template #no-options="{  }">
                                            Nenhum estado encontrado.
                                        </template>
                                    </v-select>

                                    <b-form-invalid-feedback
                                        id="input-state">{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>
                        <div class="col-lg-3">
                            <validation-provider
                                name="City"
                                :rules="{ required: true}"
                                v-slot="validationContext">
                                <b-form-group id="input-city" label="Cidade*" label-for="input-city">
                                    <b-form-input
                                        v-model="form.city"
                                        :state="getValidationState(validationContext) && null">
                                    </b-form-input>

                                    <b-form-invalid-feedback
                                        id="input-city">{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>
                        <div class="col-lg-4">
                            <validation-provider
                                name="Need"
                                :rules="{ required: true}"
                                v-slot="validationContext">
                                <b-form-group id="input-need" label="Qual a sua necessidade?*"
                                              label-for="input-need">
                                    <v-select
                                        class="camerite-dropdown"
                                        v-model="form.need"
                                        :options="needOptions"
                                        label="text">
                                        <template #no-options="{  }">
                                            Nenhuma opção encontrada.
                                        </template>
                                    </v-select>

                                    <b-form-invalid-feedback
                                        id="input-need">{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>
                    </b-row>

                    <b-row class="pb-lg-3">
                        <div class="offset-lg-2 col-lg-8">
                            <b-form-group id="input-msg" label="Mensagem" label-for="input-msg">
                                <b-form-textarea
                                    id="textarea"
                                    v-model="form.message"
                                    rows="4"
                                    max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </div>
                    </b-row>

                    <b-row class="pb-lg-5 pb-3">
                        <div class="offset-lg-8 col-lg-2">
                            <button v-on:click="onSubmit" class="tangerine w-100">Enviar</button>
                        </div>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-container>

        <CameriteFooter/>

        <MessageSentModal ref="msgSentModal" @modalDestroy="resetForm()" />
    </div>
</template>

<script>
    import CameriteHeader from "../components/camerite-header/CameriteHeader";
    import CameriteFooter from "../components/CameriteFooter";
    import MessageSentModal from "../components/MessageSentModal";

    export default {
        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    state: '',
                    city: '',
                    need: '',
                    message: ''
                },
                stateOptions: [
                    {"name": "Acre", "initials": "AC"},
                    {"name": "Alagoas", "initials": "AL"},
                    {"name": "Amapá", "initials": "AP"},
                    {"name": "Amazonas", "initials": "AM"},
                    {"name": "Bahia", "initials": "BA"},
                    {"name": "Ceará", "initials": "CE"},
                    {"name": "Distrito Federal", "initials": "DF"},
                    {"name": "Espírito Santo", "initials": "ES"},
                    {"name": "Goiás", "initials": "GO"},
                    {"name": "Maranhão", "initials": "MA"},
                    {"name": "Mato Grosso", "initials": "MT"},
                    {"name": "Mato Grosso do Sul", "initials": "MS"},
                    {"name": "Minas Gerais", "initials": "MG"},
                    {"name": "Pará", "initials": "PA"},
                    {"name": "Paraíba", "initials": "PB"},
                    {"name": "Paraná", "initials": "PR"},
                    {"name": "Pernambuco", "initials": "PE"},
                    {"name": "Piauí", "initials": "PI"},
                    {"name": "Rio de Janeiro", "initials": "RJ"},
                    {"name": "Rio Grande do Norte", "initials": "RN"},
                    {"name": "Rio Grande do Sul", "initials": "RS"},
                    {"name": "Rondônia", "initials": "RO"},
                    {"name": "Roraima", "initials": "RR"},
                    {"name": "Santa Catarina", "initials": "SC"},
                    {"name": "São Paulo", "initials": "SP"},
                    {"name": "Sergipe", "initials": "SE"},
                    {"name": "Tocantins", "initials": "TO"}
                ],
                needOptions: [
                    {text: 'Tenho interesse na Franquia Camerite', value: 'INTEREST_IN_FRANCHISE'},
                    {text: 'Gostaria de falar com o suporte', value: 'WOULD_LIKE_TO_TALK_TO_SUPPORT'},
                    {text: 'Quero resolver pendências financeiras', value: 'SOLVE_FINANCIAL_PENDINGS'},
                    {text: 'Quero trabalhar na Camerite', value: 'WORK_IN_CAMERITE'},
                    {text: 'Gostaria de colocar câmeras na minha rua', value: 'PUT_CAMERAS_AT_HOME'},
                    // {text: 'Gostaria de colocar a Camerite na minha cidade', value: 'PUT_CAMERAS_IN_THE_CITY'},
                    {text: 'Outro', value: 'OTHER'}
                ]
            }
        },
        name: "TalkToUs",
        components: {
            MessageSentModal,
            CameriteHeader,
            CameriteFooter
        },
        methods: {
            getValidationState({dirty, validated, valid = null}) {
                return dirty || validated ? valid : null;
            },
            onSubmit() {
                var vm = this;

                this.$refs.observer.validate().then(isValid => {
                    if (isValid) {
                        vm.form.need = vm.form.need.value;
                        fetch(`https://app.camerite.com/api/contact`, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            method: "POST",
                            body: JSON.stringify(vm.form)
                        })
                        vm.$refs.msgSentModal.show();
                    }
                })
            },
            resetForm() {
                this.form.name = '';
                this.form.email = '';
                this.form.phone = '';
                this.form.state = '';
                this.form.city = '';
                this.form.need = '';
                this.form.message = '';

                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            }
        }
    }
</script>
