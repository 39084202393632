<template>
    <div class="privacy">
        <CameriteHeader />

        <b-container class="py-5">
            <div class="pb-4">
                <div class="pb-3">
                    <h2>Política de privacidade</h2>
                </div>

                <div>
                    <p class="font-weight-bold">Última atualização feita em 28 de maio de 2024.</p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">SOBRE ESTA POLÍTICA E A QUEM SE APLICA</b></p>
                </div>

                <div>
                    <p>
                        Esta política, em conjunto com nossos <a class="bold-text" href="termos-de-uso">Termos de Uso</a>, esclarece como coletamos
                        e gerenciamos suas
                        informações em nosso site, aplicativos e demais serviços (doravante denominados “Serviços”).
                        Revisamos esta política periodicamente para assegurar sua atualização. Em caso de alterações
                        significativas, notificaremos você na próxima vez que acessar nossos Serviços ou por outros meios
                        de comunicação.
                    </p>

                    <p>
                        O termo “dados pessoais” será frequentemente mencionado nesta política. Esse termo refere-se a
                        qualquer informação que possa identificá-lo como indivíduo, incluindo, mas não se limitando a,
                        seu nome, informações de contato, endereço de IP, cookies ou IDs de dispositivo. Também abrange
                        informações sobre como você utiliza nossos Serviços ou o país onde você está, desde que tais
                        informações estejam associadas a identificadores pessoais.
                    </p>

                    <p>
                        Dependendo do Serviço, podemos fornecer informações adicionais ou declarações de privacidade
                        específicas. Tais informações serão claramente indicadas e aplicáveis a determinadas interações
                        ou serviços. Por exemplo, ao utilizar um de nossos aplicativos móveis, poderá ser fornecido um
                        aviso específico referente aos dados coletados por esse aplicativo.
                    </p>

                    <p>
                        Esta política aplica-se exclusivamente às nossas práticas de coleta e uso de informações. Não
                        somos responsáveis pela coleta ou uso de suas informações por empresas parceiras, meios de
                        pagamento, servidores de aplicação, provedores de infraestrutura técnica, atendimento ao cliente
                        e ferramentas de autenticação (referidos como <b>“Fornecedores de Terceiros”</b>). Ao interagir
                        com esses Fornecedores de Terceiros, suas informações serão processadas conforme as políticas de
                        privacidade e termos destes. <b>Não somos responsáveis pelo conteúdo ou compliance de privacidade
                            dos sites ou aplicativos de terceiros, </b> recomendamos que você verifique as políticas de
                        privacidade desses terceiros para compreender como suas informações são utilizadas.
                    </p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">DADOS PESSOAIS QUE COLETAMOS</b></p>
                </div>

                <div>
                    <p>
                        Coletamos informações pessoais decorrentes das interações dos usuários conosco, bem como de
                        determinados terceiros e outras fontes, como empregadores ou assinantes que tenham acesso aos
                        nossos serviços, além de fontes disponíveis ao público, onde permitido por lei, ou conforme o
                        uso dos nossos serviços. Essas informações englobam dados como endereço IP, detalhes sobre o
                        dispositivo e navegador utilizados para acessar nossos serviços, o URL do site de origem da
                        visita e sites de terceiros acessados por meio de links externos no site da Camerite.
                        Além disso, podemos obter informações sobre a localização do usuário por meio do dispositivo
                        móvel ou endereço IP. Tais práticas visam aprimorar a experiência do usuário e garantir a
                        entrega de conteúdo relevante. É importante observar que, em muitos casos, os usuários têm a
                        opção de escolher se desejam ou não fornecer informações pessoais. No entanto, a não
                        disponibilização dessas informações pode resultar na limitação da funcionalidade completa dos
                        serviços oferecidos.
                    </p>

                    <p>As informações pessoais que coletamos incluem:</p>

                    <p><b>• Nome, CPF/CNPJ e Dados de Contato:</b> Tais como primeiro e último nome, CPF/CNPJ, endereço de
                        e-mail, endereço postal e número de telefone, que servem para confirmar a identidade do usuário.<br>
                        <b>• Credenciais de Conta: </b>Como senhas e outras informações de segurança utilizadas para
                        autenticação e acesso aos nossos serviços.<br>
                        <b>• Conteúdo do Usuário: </b>Compreendendo comunicações e arquivos fornecidos pelos usuários no
                        contexto de uso dos nossos serviços.<br>
                        <b>• Informações de Pagamento: </b>Destaca-se que não armazenamos dados sensíveis de cartões de
                        crédito/débito, sendo as informações criptografadas e encaminhadas ao gateway de pagamento por meio
                        de uma conexão TLS.<br>
                        <b>• Informações do Dispositivo: </b>Incluindo endereço IP, localização e provedor de serviços.<br>
                        <b>• Informações de Uso e Histórico de Navegação: </b>Englobando detalhes sobre a navegação
                        realizada dentro dos nossos serviços e quais funcionalidades são mais utilizadas.<br>
                        <b>• Dados de Localização: </b>Quando necessário e com consentimento prévio do usuário.<br>
                        <b>• Informações Demográficas: </b>Tais como país de origem e idioma de preferência.<br>
                        <b>• Informações de Uso e Perfil: </b>Relativas à interação do usuário com nossos serviços, como
                        tempo de uso, páginas visitadas e links acessados.<br>
                        <b>• Informações de Preferências: </b>Incluindo autorizações específicas concedidas ou negadas,
                        preferências de e-mail, notificações push e configurações de cookies relacionadas à publicidade.<br>
                        <b>• Informações de Comunicação: </b>Correspondendo a comunicações recebidas dos usuários (chat),
                        como feedback, solicitações de suporte e perguntas por e-mail, bem como metadados associados a essas
                        comunicações.<br>
                        <b>• Conteúdo Gerado ou Fornecido pelo Usuário: </b>Compreendendo comentários, fotos, vídeos,
                        recomendações, avaliações e metadados associados, como data e hora de envio.<br>
                        <b>• Informações de Redes Sociais: </b>Obtidas mediante consentimento voluntário dos usuários,
                        através de cadastros, pesquisas, interações promocionais e outros meios.
                    </p>

                    <p>Também coletamos informações pessoais sobre você de terceiros, tais como:</p>

                    <p>
                        <b>• a(s) pessoa(s) que possibilita(m) o acesso a nossos serviços</b> (por exemplo, seu empregador
                        ou nosso assinante) para configurar uma conta de usuário.<br>
                        <b>• a organização à qual você pertence</b> onde essa organização permite a você acesso aos nossos
                        serviços).<br>
                        <b>• parceiros e fornecedores de serviços que trabalham conosco</b> em relação a seu serviço.<br>
                        <b>• fontes publicamente disponíveis,</b> como sites públicos, bancos de dados de governo abertos ou
                        outros dados em domínio público, para nos ajudar a manter a precisão dos dados, fornecer e aprimorar
                        os serviços.
                    </p>

                    <p>
                        Os tipos de informações pessoais que coletamos dependem de como você está interagindo conosco e
                        quais serviços está adquirindo ou utilizando.
                    </p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">QUAIS SÃO SUAS OPÇÕES E DIREITOS</b></p>
                </div>

                <div>
                    <p>
                        Se possui uma conta na Camerite, você pode solicitar ao administrador para editar ou excluir os
                        principais dados pessoais associados ao seu perfil a qualquer momento, através do CRM.
                    </p>

                    <p>
                        Você também possui direitos em relação aos dados pessoais que temos, que explicaremos com mais
                        detalhes abaixo. Você também pode exercer esses direitos entrando em contato conosco pelo e-mail <a
                            href="mailto:contato@camerite.com">contato@camerite.com</a>.
                    </p>

                    <p>
                        Você tem o direito de solicitar uma cópia dos seus dados pessoais, de corrigir, excluir ou
                        restringir o processamento dos seus dados pessoais e de obter os dados pessoais fornecidos a nós em
                        um formato estruturado e legível eletronicamente. Além disso, você pode fazer uma objeção ao
                        processamento dos seus dados pessoais em algumas circunstâncias (em particular, quando não temos de
                        processar os dados para cumprir exigências contratuais ou legais). Quando solicitamos sua
                        autorização, você pode revogar a autorização a qualquer momento. Se você desejar revogar a
                        autorização da Camerite para processar seus dados, isso não afetará o processamento que já tenha
                        sido realizado até o momento.
                    </p>

                    <p>
                        Vamos honrar seus direitos sob as leis de proteção a dados aplicáveis. Você tem os seguintes
                        direitos de acordo com a legislação europeia e pode ter direitos semelhantes sob as leis de outros
                        países.
                    </p>

                    <p>
                        <b>• Direito de acesso ao sujeito:</b> o direito de fazer uma solicitação por escrito para obter
                        detalhes das suas informações pessoais e uma cópia dessas informações.<br>
                        <b>• Direito a retificação:</b> o direito de ter removidas ou corrigidas as informações imprecisas
                        sobre você.<br>
                        <b>• Direito de apagar os dados (“direito ao esquecimento”):</b> o direito de ter determinadas
                        informações pessoais a seu respeito apagadas.<br>
                        <b>• Direito a restrição de processamento:</b> o direito de solicitar que suas informações pessoais
                        sejam usadas somente para fins restritos.<br>
                        <b>• Direito de recusar o marketing:</b> você pode gerenciar suas preferências de marketing pelos
                        links de cancelamento de assinatura localizados na comunicação que receber de nós ou acessando o
                        centro de preferência aplicável.<br>
                        <b>• Direito de rejeitar:</b> o direito de não concordar com o tratamento de suas informações
                        pessoais nos casos em que nosso tratamento é baseado no desempenho de uma tarefa executada no
                        interesse público ou de permitir que você saiba que o tratamento é necessário para nossos interesses
                        legítimos ou de um terceiro.<br>
                        <b>• Direito à portabilidade de dados:</b> o direito de solicitar as informações pessoais que você
                        nos disponibilizou para serem transferidas a você ou em formato legível por máquina.<br>
                        <b>• Direito de cancelar o consentimento:</b> o direito de cancelar qualquer consentimento dado
                        previamente a nós para lidar com suas informações pessoais. Se você cancelar o consentimento, isso
                        não afetará a legalidade do nosso uso de suas informações pessoais antes do cancelamento de seu
                        consentimento.
                    </p>

                    <p>Esses direitos não são absolutos e eles nem sempre se aplicam a todos os casos.</p>

                    <p>
                        Em resposta a uma solicitação, pediremos que você verifique sua identidade se precisarmos e para
                        fornecer informações que nos ajudem a entender sua solicitação melhor. Se não atendermos sua
                        solicitação, seja no todo ou em parte, explicaremos por quê.
                    </p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">COMO COMPARTILHAMOS AS INFORMAÇÕES PESSOAIS</b></p>
                </div>

                <div>
                    <p>
                        Compartilhamos informações pessoais no grupo da Camerite (controladores, coligadas, afiliadas e
                        controladas), com nossos parceiros de negócios e provedores de serviços terceirizados, a pessoa que
                        fornece seu acesso aos nossos serviços (se não for você) e de acordo com a legislação. Nossos
                        prestadores de serviços terceirizados não têm permissão para compartilhar ou utilizar informações
                        pessoais que disponibilizamos para qualquer finalidade que não seja a prestação de serviços.
                    </p>

                    <p>Compartilhamos suas informações para os fins descritos nesta declaração, com as seguintes categorias
                        de destinatários:</p>

                    <p>
                        • empresas do grupo Camerite ou da qual a mesma faça parte.<br>
                        • a pessoa que fornece o acesso aos nossos serviços (por exemplo, seu empregador ou nossos
                        assinantes).<br>
                        • parceiros de negócios com quem oferecemos serviços de marcas conjuntas, fornecemos conteúdo ou
                        para organizar eventos, conferências e seminários.<br>
                        • terceiros que nos ajudam a oferecer serviços ou tomar providências em nosso nome.<br>
                        • terceiros pelos quais temos uma responsabilidade ou que têm permissão para divulgar suas
                        informações pessoais por lei (por exemplo, órgãos governamentais, agências reguladoras, poder
                        judiciário e outras autoridades públicas).<br>
                        • terceiros a fim de participar de, ou ser o assunto de, qualquer venda, fusão, aquisição
                        reestruturação, joint venture, cessão, transferência ou outra disposição de toda ou qualquer parte
                        de nosso negócio, ativos ou estoque (inclusive em conexão com qualquer processo de falência ou
                        similar), caso em que podemos divulgar seus dados pessoais para possíveis compradores, vendedores,
                        consultores ou parceiros e seus dados podem ser um ativo transferido em uma venda comercial.<br>
                        • terceiros onde for razoavelmente necessário proteger nossos direitos, usuários, sistemas e
                        serviços (por exemplo, consultoria jurídica e profissionais de segurança das informações).<br>
                        • qualquer pessoa com a qual você nos solicite para compartilhar informações (por exemplo, se você
                        carregar informações em um fórum público, elas serão compartilhadas publicamente).
                    </p>

                    <p>
                        <b>Compartilhando dados pessoais com Fornecedores Terceiros que processam esses dados somente
                            seguindo nossas instruções.</b>
                    </p>

                    <p>
                        Compartilhamos informações relacionadas a nossos usuários com terceiros selecionados que nos
                        fornecem uma variedade de serviços que suportam a entrega dos nossos serviços (“Fornecedores
                        Terceiros”). Asseguramos que o gerenciamento de informações feito em nosso nome por Fornecedores
                        Terceiros será feito de acordo com termos contratuais, que requerem que essas informações sejam
                        mantidas seguras, sejam processadas de acordo com as leis de proteção de dados e usadas somente
                        conforme instruímos e não para os propósitos dos Fornecedores Terceiros (a menos que tenham a sua
                        autorização).
                    </p>

                    <p>
                        Fornecedores Terceiros podem estar alocados ou processar suas informações fora do país onde você
                        está baseado. Nos casos em que o nosso uso de Fornecedores Terceiros envolver a transferência de
                        dados pessoais de dentro da Europa para fora do Espaço Econômico Europeu, nós tomaremos as medidas
                        necessárias para garantir que os dados pessoais sejam devidamente protegidos na nova localização,
                        geralmente aplicando disposições contratuais padrão aprovadas pela comissão europeia e fazendo
                        verificações de segurança rigorosas.
                    </p>

                    <p>
                        Os tipos de Fornecedores Terceiros com os quais podemos compartilhar elementos dos seus dados
                        pessoais incluem:
                    </p>

                    <p>
                        • processadores de pagamento acionados por nós para armazenar e gerenciar informações de pagamento
                        com segurança, como detalhes de cartão de crédito ou débito, necessárias para facilitar a
                        contratação dos serviços.<br>
                        • fornecedores de gerenciamento de e-mail e ferramentas de distribuição, por exemplo, se você
                        assinar o recebimento de newsletters da Camerite ou outras mensagens comerciais, gerenciamos seu
                        envio usando uma ferramenta terceirizada de distribuição de e-mail;<br>
                        • fornecedores de serviços de segurança e prevenção de fraudes. Por exemplo, usamos esses
                        fornecedores para identificar agentes de software automatizado que podem prejudicar nossos serviços
                        ou para prevenir o uso indevido de nossas APIs;<br>
                        • fornecedores de serviços de agregação de dados e software de análise que nos possibilitam
                        monitorar e otimizar com eficácia a entrega dos nossos serviços;<br>
                        • fornecedores de ferramentas de rastreamento que usamos para monitorar instâncias em que você clica
                        em um link para o site de um terceiro;<br>
                        • fornecedores de plataformas de software que nos ajudam na comunicação ou no fornecimento de
                        serviços de atendimento ao cliente, por exemplo, gerenciamos e respondemos todas as mensagens
                        enviadas por meio da nossa central de ajuda usando uma ferramenta terceirizada de gerenciamento de
                        comunicações;<br>
                        • fornecedores de serviços de armazenamento na nuvem online e outros serviços de TI essenciais;
                    </p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">SEGURANÇA DOS DADOS PESSOAIS</b></p>
                </div>

                <div>
                    <p>Manter seus dados pessoais protegidos é nossa maior prioridade. Limitamos o acesso a somente aqueles
                        funcionários da Camerite que têm de entrar em contato com as suas informações para realizarem seu
                        trabalho e entregar nossos serviços.</p>

                    <p>Infelizmente, nenhum site ou aplicativo pode garantir total segurança, mas criamos um programa de
                        segurança que abrange toda a empresa, projetado para manter seus dados pessoais o mais seguros
                        possível. Ele usa uma variedade de medidas técnicas, organizacionais e administrativas de segurança
                        e técnicas de melhores práticas, dependendo dos tipos de dados que estão sendo processados. Por
                        exemplo, os sistemas de computadores que usamos para armazenar seus dados têm limitações de acesso e
                        servidores baseados na nuvem que usam criptografia de disco padrão da indústria. Usamos criptografia
                        TLS e HTTPS para proteger seus dados pessoais em transferências pela internet.</p>

                    <p>Desenvolvemos nossos serviços com o objetivo de usar o mínimo de dados pessoais possível, incluindo
                        por meio do uso de técnicas de minimização de dados, como anonimização e pseudo anonimização. Além
                        disso, sempre que desenvolvemos ou atualizamos nossos serviços de maneira que envolve a coleta ou o
                        uso de novas formas de dados pessoais, realizamos uma avaliação de impacto na privacidade para
                        entender e reduzir a probabilidade de impactos não desejáveis à você.</p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">ONDE ARMAZENAMOS SUAS INFORMAÇÕES</b></p>
                </div>

                <div>
                    <p>Seus dados são armazenados com segurança em centrais de dados em todo o mundo - o local exato depende
                        de onde você está quando usa a Camerite. Seus dados serão processados somente por fornecedores que
                        oferecem a segurança contratual adequada para as informações que processam. Às vezes, seus dados
                        podem ser armazenados em países com diferentes níveis de segurança, mas sempre garantimos que seus
                        padrões correspondam aos nossos. Temos em vigor medidas para garantir que, quando suas informações
                        pessoais são transferidas internacionalmente, elas estão sujeitas a medidas de proteção de acordo
                        com as leis de proteção de dados. Geralmente, esses recursos incluem proteções contratuais.</p>

                    <p>Colaboramos com terceiros, como serviços de hospedagem em nuvem, fornecedores e suporte de tecnologia
                        localizados em todo o mundo para atender às necessidades de nossos negócios, mão de obra e clientes.
                        Tomamos as medidas apropriadas para assegurar que as informações pessoais sejam processadas, fiquem
                        seguras e sejam transferidas de acordo com a lei aplicável. Em alguns casos, pode ser preciso
                        divulgar ou transferir suas informações pessoais dentro da Camerite ou a terceiros em áreas fora de
                        seu país, incluindo países que não tenham sido declarados adequados para fins de proteção de dados
                        pela Comissão Europeia.</p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">POR QUANTO TEMPO ARMAZENAMOS SUAS INFORMAÇÕES PESSOAIS</b></p>
                </div>

                <div>
                    <p>Mantemos seus dados somente pelo período necessário ou quando somos obrigados por motivos legais.
                        Portanto, temos de excluí-los ou anonimizá-los para que não identifiquem você. Tratamos os dados de
                        maneira diferente dependendo do propósito de uso, mas você poderá solicitar a exclusão dos seus
                        dados a qualquer momento.</p>

                    <p>Mantemos seus dados pessoais somente pelo tempo necessário. Isso depende do motivo da coleta ou se
                        temos uma base legal contínua para mantê-los (como para cumprir um contrato entre nós, realizar um
                        serviço solicitado por você ou por nossos interesses legítimos). Garantimos que, se não tivermos
                        motivos ou necessidade legal para processar seus dados pessoais, excluiremos ou armazenamos seus
                        dados de maneira que não identifiquem você.</p>

                    <p>Temos políticas de retenção diferentes para tipos diferentes de dados pessoais, levando em
                        consideração:</p>

                    <p>• a razão para coletar os dados pessoais;<br>
                        • quanto tempo levará para cumprir esse propósito;<br>
                        • qualquer razão específica ou obrigação legal superior para reter os dados pessoais por um período
                        específico.</p>

                    <p>Além disso, se você tem uma conta conosco, manteremos dados pessoais como seu endereço de e-mail,
                        nome e outros detalhes para que possa realizar login e acessar nossos serviços pelo período em que
                        mantém uma conta. Podemos manter outras informações por tempo indefinido, como atividade no site
                        vinculada a um endereço IP, que usamos para ajudar a entender nossos usuários, melhorar nossos
                        produtos e serviços e proteger nossos interesses empresariais.</p>

                    <p>Não importa a duração do período de retenção, você poderá solicitar a exclusão dos seus dados
                        pessoais em determinadas circunstâncias. Saiba mais sobre como você pode gerenciar seus dados
                        pessoais na seção “Quais são suas opções e direitos” desta política.</p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">MARKETING</b></p>
                </div>

                <div>
                    <p>Fornecemos comunicações de marketing e eventos a você por meio de várias plataformas, como e-mail,
                        telefone, mensagens de texto, correio direto e on-line. Quando exigido por lei, pediremos que você
                        explicitamente opte pelo recebimento de nosso marketing. Se enviarmos uma comunicação de marketing,
                        ela incluirá instruções sobre como optar por receber essas comunicações no futuro.</p>

                    <p>É importante para nós honrar suas preferências de marketing. Você tem o direito de cancelar o
                        recebimento de marketing direto e destinado a publicidade on-line.</p>

                    <p><b>Como cancelar o marketing por e-mail?</b></p>

                    <p>Quando podemos enviar e-mails de marketing, oferecemos opções de cancelamento em nossos e-mails. Para
                        atualizar suas preferências de marketing por e-mail, acesse o centro de preferência de e-mail
                        aplicável, um link para o qual normalmente será incluído nos e-mails que enviarmos a você. Além
                        disso, você também pode usar a opção “Fale com a gente” de um determinado serviço, falar com seu
                        contato da Camerite ou entrar em contato com nossa equipe de privacidade através do e-mail <a
                            href="mailto:contato@camerite.com">contato@camerite.com</a>.</p>

                    <p>Mesmo que você opte por não receber comunicações de marketing por e-mail, ainda poderemos enviar-lhe
                        comunicações de serviço ou importantes informações transacionais relacionadas às suas contas e
                        assinaturas (para finalidades tais como prestação de atendimento ao cliente).</p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">COOKIES E TECNOLOGIAS SIMILARES</b></p>
                </div>

                <div>
                    <p>Usamos cookies e tecnologias similares para ajudar a entregar, otimizar, personalizar e analisar
                        nossos serviços e por motivos de publicidade. Usamos uma combinação de cookies e outras tecnologias,
                        como pixels/web beacons e códigos de rastreamento (explicados abaixo), para coletar informações para
                        uso em conformidade com os propósitos dispostos nesta política.</p>

                    <p><b>O que é um cookie?</b></p>

                    <p>Um cookie é um pequeno arquivo de dados que é armazenado no seu computador ou dispositivo. Os cookies
                        registram informações como suas preferências e configurações e nos ajudam com dados que precisamos
                        para oferecer a você a experiência mais consistente e relevante. Também usamos os cookies para
                        identificar a origem do tráfego do usuário, medir a eficácia de campanhas, ações, etc e para
                        registrar informações sobre experimentos ou novas melhorias que podemos testar para monitorar como
                        você usa nossos serviços e saber se são eficazes.</p>

                    <p>Os cookies em nossos sites podem ser configurados por nós, por terceiros com contratos conosco ou por
                        terceiros independentes (como agências de publicidade).</p>

                    <p><b>O que são web beacons e pixels?</b></p>

                    <p>Web beacons ou pixels são pequenos arquivos de imagens transparentes dentro de uma página de internet
                        ou e-mail. Usamos eles para entender como você interage com os nossos serviços ou se você foi
                        redirecionado ao site ou aplicativo de um Fornecedor Terceiro. Os pixels também são utilizados para
                        saber se você abriu um e-mail, para que possamos melhorar nossas comunicações.</p>

                    <p><b>O que são códigos de rastreamento?</b></p>

                    <p>Códigos de rastreamento são fragmentos de código inseridos nas páginas para medir informações como
                        visitas e interações. Usamos códigos de rastreamento para saber mais sobre como você interage com os
                        nossos serviços, sobre os anúncios que vê e em linhas gerais, como nos usa.</p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <p><b class="cap-text">COMO ENTRAR EM CONTATO COM A GENTE</b></p>
                </div>

                <div>
                    <p>Se você tiver dúvidas, comentários, reclamações ou sugestões em relação à proteção de dados ou esta
                        Política ou alguma outra preocupação sobre a forma como tratamos suas informações, entre em contato
                        com nossa equipe em
                        <a href="mailto:contato@camerite.com">contato@camerite.com</a> ou através no nosso whats app (047)
                        9221-9900.
                    </p>

                    <p>Camerite Sistemas S.A (“Camerite”), é uma empresa regularmente registrada no Brasil inscrita no CNPJ
                        sob 05.818.541/0001-45, com sede na Av. Santos Dumont, 935, térreo, Bairro: Santo Antônio, CEP:
                        89.218-105, cidade de Joinville, Estado de Santa Catarina, Brasil.</p>

                    <p>Camerite é uma empresa coligada das seguintes empresas: Neogrid Netherlands Holding BV (Holanda
                        Amsterdã), Neogrid Netherlands BV (Amsterdã - Holanda), Neogrid North America LLC (Escritório
                        Chicago - Delaware LLC), Neogrid AP Inc (Tóquio - Japão), Neogrid International LLC (Delaware -
                        EUA), Neogrid International French Branch, Neogrid Europe Ltd (St. Albans - Reino Unido), Neogrid
                        Software SA (Brasil), Neogrid Informática SA (Brasil).</p>
                </div>
            </div>

            <div>
                <div>
                    <p><b class="cap-text">ATUALIZAÇÕES DESTA DECLARAÇÃO</b></p>
                </div>

                <div>
                    <p>
                        Esta Declaração pode estar sujeita a atualizações. Quaisquer alterações futuras ou adições ao
                        material para processamento de informações pessoais como descrito nesta Declaração que afetem a você
                        lhe serão enviadas por meio de um canal adequado. Por exemplo, podemos colocar um aviso visível em
                        um site do produto ou enviar um e-mail informando sobre a atualização da Declaração.
                    </p>
                </div>
            </div>
        </b-container>

        <CameriteFooter />
    </div>
</template>

<script>
import CameriteHeader from "../components/camerite-header/CameriteHeader";
import CameriteFooter from "../components/CameriteFooter";

export default {
    data() {
        return {}
    },
    name: "TalkToUs",
    components: {
        CameriteHeader,
        CameriteFooter
    },
}
</script>

<style lang="scss" scoped></style>
