var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-jumbotron',{staticClass:"camerite-jumbotron",class:{'mobileContainer': _vm.isTrueMobile},style:({
    backgroundImage: 'url(' + _vm.finalImg + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover' ,
    backgroundPositionX: _vm.isTrueMobile ? '100%' : _vm.pos,
    backgroundPositionY: _vm.isTrueMobile ? '100%' : '25%',
    display: _vm.isTrueMobile ? 'flex' : 'block',
    alignItems: _vm.mobileTextPos === 'bottom' ? 'flex-end' : 'flex-start'
}),attrs:{"fluid":""}},[_c('b-container',{staticClass:"px-0"},[_c('div',{staticClass:"col-lg-6 col-md-7 col-sm-12 px-0 py-lg-5"},[(_vm.top && !_vm.isTrueMobile)?_c('div',{staticClass:"pb-3"},[_c('span',{staticClass:"font-weight-bold",class:{'text-white': _vm.textWhite}},[_vm._v(_vm._s(_vm.top))])]):_vm._e(),(_vm.main)?_c('div',{staticClass:"pb-3"},[_c('h1',{class:{'revert-text': _vm.isTrueMobile, 'text-white': _vm.textWhite}},[_vm._v(_vm._s(_vm.main))])]):_vm._e(),(_vm.bottom)?_c('div',[_c('span',{staticClass:"support-text",class:{'revert-text': _vm.isTrueMobile, 'text-white': _vm.textWhite}},[_vm._v(_vm._s(_vm.bottom))])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }