<template>
  <div class="talk-to-us">
    <CameriteHeader />
    <CameriteJumbotron
      :main="heroMainText"
      :bottom="heroBottomText"
      img="/landing-page-media/jumbos/Topo_VC_Gov_Sobre.png"
      :text-white="true"
    />

    <b-container class="py-2 my-2 my-lg-5">
      <b-row class="pb-5 mb-5">
        <div class="col-lg-5 pb-5 pb-lg-0">
          <div class="pb-3">
            <h2>Parceiros e investidores</h2>
          </div>

          <div>
            <p>
              A Camerite, junto a Microsoft oferece soluções de monitoramento
              para cidades através de inteligência artificial e câmeras de
              vigilância integradas, com armazenamento 100% em Nuvem.
            </p>
            <p>
              Fornecemos tecnologia e inteligência artificial para que você
              entregue mais segurança para sua cidade através de câmeras de
              vigilância inteligentes.
            </p>
          </div>
        </div>

        <div
          class="offset-lg-1 col-lg-5 d-lg-flex flex-lg-column justify-content-lg-center"
        >
          <b-row class="pb-4 mx-0">
            <div class="col-4">
              <div>
                <h2 class="tangerine-text about-us-headline text-center">
                  +300 mil
                </h2>
              </div>
              <div class="text-center">
                <span class="font-weight-bold about-us-low-line">usuários</span>
              </div>
            </div>

            <div class="col-4">
              <div>
                <h2 class="tangerine-text about-us-headline text-center">
                  +600
                </h2>
              </div>
              <div class="text-center">
                <span class="font-weight-bold about-us-low-line"
                  >cidades monitoradas</span
                >
              </div>
            </div>

            <div class="col-4">
              <div>
                <h2 class="tangerine-text about-us-headline text-center">
                  Pioneira
                </h2>
              </div>
              <div class="text-center">
                <span class="font-weight-bold about-us-low-line"
                  >na América Latina</span
                >
              </div>
            </div>
          </b-row>

          <b-row class="pb-2 mx-0">
            <div class="col-6">
              <b-img
                fluid
                src="/landing-page-media/about/Microsoft%20Partner.png"
              ></b-img>
            </div>
            <div class="col-6">
              <b-img
                fluid
                src="/landing-page-media/about/endeavor_logo.png"
              ></b-img>
            </div>
          </b-row>
        </div>
      </b-row>

      <b-row class="pb-5 mb-5">
        <div class="col-lg-3 pb-5">
          <div class="pb-4">
            <h2>Prêmios</h2>
          </div>
          <div class="pb-4 col-8 col-md-6 col-lg-12">
            <b-img
              fluid
              src="/landing-page-media/about/movidesk_logo.png"
            ></b-img>
          </div>
          <div>
            <p>
              Melhor índice de satisfação do cliente do Brasil pela Movidesk
            </p>
          </div>
        </div>
        <div class="offset-lg-3 col-lg-5">
          <div class="pb-4">
            <h2>Na imprensa</h2>
          </div>

          <b-row class="mx-0">
            <div class="col-5">
              <div class="pb-3 col-8 col-md-6 col-lg-10 px-0">
                <b-img
                  fluid
                  src="/landing-page-media/about/uol_logo.png"
                ></b-img>
              </div>
              <div>
                <span class="about-us-headline-blue">UOL - 14/02/2020</span>
              </div>
              <div>
                <span>Drones em SP</span>
              </div>
              <div>
                <span
                  class="hyperlink clickable underline"
                  v-on:click="
                    open(
                      'https://www.uol.com.br/carnaval/2020/noticias/estadao/2020/02/14/carnaval-de-sp-tera-drone-reconhecimento-facial-e-15-mil-policiais-por-dia.htm'
                    )
                  "
                >
                  Saiba mais
                </span>
              </div>
            </div>

            <div class="offset-lg-2 col-6 col-lg-5">
              <div class="pb-3 col-8 col-md-6 col-lg-10 px-0">
                <b-img
                  fluid
                  src="/landing-page-media/about/pegn_logo.png"
                ></b-img>
              </div>
              <div>
                <span class="about-us-headline-blue">PEGN - 25/09/2019</span>
              </div>
              <div>
                <span>Monitoramento na nuvem</span>
              </div>
              <div>
                <span
                  class="hyperlink clickable underline"
                  v-on:click="
                    open(
                      'https://revistapegn.globo.com/Publicidade/Microsoft/noticia/2019/09/monitoramento-em-nuvem-permite-seguranca-urbana-com-alta-tecnologia-e-baixo-custo.html'
                    )
                  "
                >
                  Saiba mais
                </span>
              </div>
            </div>
          </b-row>
        </div>
      </b-row>

      <b-row class="pb-5 mb-5">
        <div class="col-12 pb-5">
          <h2 class="text-center">Nossos diferenciais</h2>
        </div>

        <div class="col-12">
          <b-row>
            <div class="offset-lg-1 col-lg-2 col-sm-4 pb-4 pb-lg-0">
              <div class="text-center pb-3">
                <b-img
                  fluid
                  style="height: 64px; width: 64px"
                  src="/landing-page-media/about/Grupo_armazenamento.png"
                >
                </b-img>
              </div>

              <div>
                <p class="text-center about-us-super-low-line">
                  Armazenamento 100% em nuvem
                </p>
              </div>
            </div>

            <div class="col-lg-2 col-sm-4 pb-4 pb-lg-0">
              <div class="text-center pb-3">
                <b-img
                  fluid
                  style="height: 64px; width: 64px"
                  src="/landing-page-media/about/Grupo_no_server.png"
                >
                </b-img>
              </div>

              <div>
                <p class="text-center about-us-super-low-line">
                  Dispensa servidores locais e infraestrutura pesada
                </p>
              </div>
            </div>

            <div class="col-lg-2 col-sm-4 pb-4 pb-lg-0">
              <div class="text-center pb-3">
                <b-img
                  fluid
                  style="height: 64px; width: 64px"
                  src="/landing-page-media/about/Grupo_LPR.png"
                >
                </b-img>
              </div>

              <div>
                <p class="text-center about-us-super-low-line">
                  Sistema de leitura de placas
                </p>
              </div>
            </div>

            <div class="col-lg-2 offset-lg-0 offset-sm-2 col-sm-4 pb-4 pb-lg-0">
              <div class="text-center pb-3">
                <b-img
                  fluid
                  style="height: 64px; width: 64px"
                  src="/landing-page-media/about/Grupo_Horus.png"
                >
                </b-img>
              </div>

              <div>
                <p class="text-center about-us-super-low-line">
                  Sistema de análise de rotas de veículos e pessoas
                </p>
              </div>
            </div>

            <div class="col-lg-2 col-sm-4 pb-4 pb-lg-0">
              <div class="text-center pb-3">
                <b-img
                  fluid
                  style="height: 64px; width: 64px"
                  src="/landing-page-media/about/Grupo_aplicativo.png"
                >
                </b-img>
              </div>

              <div>
                <p class="text-center about-us-super-low-line">
                  Acesso via aplicativos para agentes em campo
                </p>
              </div>
            </div>
          </b-row>
        </div>
      </b-row>

      <SeeHowEasyItIs />
    </b-container>

    <CameriteFooter />
  </div>
</template>

<script>
import CameriteHeader from "../components/camerite-header/CameriteHeader";
import CameriteJumbotron from "@/components/CameriteJumbotron";
import CameriteFooter from "../components/CameriteFooter";
import SeeHowEasyItIs from "@/components/SeeHowEasyItIs";

export default {
  data() {
    return {
      heroMainText: "Um sistema de segurança para todos",
      heroBottomText: `Acreditamos que a tecnologia vai revolucionar o mundo e, através dela, adicionamos
            inteligência às câmeras para transformar cidades em cidades + inteligentes e seguras.`,
    };
  },
  name: "TalkToUs",
  components: {
    CameriteHeader,
    CameriteJumbotron,
    CameriteFooter,
    SeeHowEasyItIs,
  },
  methods: {
    open: function (href) {
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.about-us-headline {
  font-size: 28px;
}

.about-us-headline-blue {
  color: $camerite-blue;
}

.about-us-low-line {
  font-size: 12px;
}

.about-us-super-low-line {
  font-size: 14px;
}

@media (max-width: 438px) {
  .about-us-headline {
    font-size: 20px;
  }
}
</style>
