<template>
    <div class="solved-crime-card py-4 px-3 mb-md-5 mb-3">
        <div class="pb-2">
            <h3>{{headline}}</h3>
        </div>
        <div class="pb-2">
            <span>{{text}}</span>
        </div>
        <div>
            <a :href="hyperlink">{{hyperlinkText}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SolvedCrimeCard",
        props: {
            headline: String,
            text: String,
            hyperlinkText: String,
            hyperlink: String
        }
    }
</script>

<style scoped lang="scss">
    div.solved-crime-card {
        border-radius: 11px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        background-color: white;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            transform: scale(1.02);
        }
    }
</style>
