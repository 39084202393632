<template>
    <div class="arrow-button" v-on:click="action" v-bind:class="{'revert': revert}">
        <font-awesome-icon :icon="'arrow-' + direction" />
    </div>
</template>

<script>
	export default {
		name: 'ArrowButton',
		props: {
			direction: String,
            action: Function,
            revert: Boolean
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    div.arrow-button {
        width: 36px;
        height: 36px;
        border: solid 2px $camerite-battleship-grey;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;
        font-size: 18px;
        color: $camerite-battleship-grey;

        &.revert {
            background-color: transparent;
            color: white;
            border: solid 2px white;

            &:hover {
                background-color: white;
                color: $camerite-blue;
            }
        }

        &:hover {
            background-color: $camerite-battleship-grey;
            color: white;
        }
    }


</style>
