<template>
    <div class="solved-crime py-5">
        <h1 class="pb-2">São Gabriel/RS</h1>

        <p class="pb-3">
            São Gabriel no Rio Grande do Sul faz parte das cidades inteligentes Camerite e ao longo do tempo vem obtendo
            excelentes resultados na redução da criminalidade.
        </p>
        <p class="pb-4">
            Segundo o delegado da polícia civil, o sistema de segurança tornou possível elucidar furtos e roubos no
            município. De acordo com a brigada militar, os crimes na cidade reduziram 80% com o sistema em operação.
        </p>


        <p class="testimony pb-2">
            “As imagens geradas pelo sistema serviram tanto para provas de homicídio, tráfico de drogas e acidentes de
            trânsito.”
        </p>
        <p class="bottom-testimony">
            José Soares de Bastos
        </p>
        <p class="bottom-testimony pb-4">
            Delegado da Polícia Civil
        </p>

        <p class="testimony pb-2">
            “É somando forças que as soluções necessárias para a comunidade são construídas.”
        </p>
        <p class="bottom-testimony">
            Rossano Gonçalves
        </p>
        <p class="bottom-testimony">
            Prefeito de São Gabriel
        </p>
    </div>
</template>

<script>
    export default {
        name: "SC4",
    }
</script>

<style scoped lang="scss">
    p {
        margin-bottom: unset;
    }

    p.testimony {
        font-size: 18px;
        color: $camerite-blue;
    }

    p.bottom-testimony, span.bottom-testimony {
        font-size: 14px;
        font-weight: bold;
    }
</style>
