<template>
  <div class="home">
    <CameriteHeader />

    <div class="home-sections">
      <div class="row mx-0 bg-film">
        <div class="col-lg-6 for-you"></div>
        <div class="col-lg-6 for-police"></div>
      </div>

      <b-container>
        <div class="row mx-0">
          <div
            class="section col-lg-5 d-flex flex-column justify-content-end pb-4"
          >
            <div>
              <div>
                <h2 class="revert-text">Para você</h2>
              </div>

              <p class="revert-text">
                Junte-se a os mais de 100 mil usuários que já utilizam a
                Camerite para proteger suas ruas, bairros e cidades com o
                Projeto Vizinhança Colaborativa.
              </p>

              <div>
                <button
                  class="tangerine"
                  v-on:click="
                    $router.push('/vizinhanca-colaborativa').catch(() => {})
                  "
                >
                  Saiba mais
                </button>
              </div>
            </div>
          </div>

          <div
            class="section col-lg-5 offset-lg-2 d-flex flex-column justify-content-end pb-4"
          >
            <div>
              <div>
                <h2 class="revert-text">Para polícia</h2>
              </div>

              <p class="revert-text">
                São mais de 800 cidades que já utilizam a Camerite na Polícia
                Militar, Polícia Civil e Guarda Metropolitana. Conheça a solução
                da maior plataforma de videomonitoramento com Inteligência
                Artificial da América Latina.
              </p>

              <div>
                <button
                  class="tangerine"
                  v-on:click="
                    $router
                      .push('/policia/cidades-inteligentes')
                      .catch(() => {})
                  "
                >
                  Saiba mais
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-container>
    </div>

    <CameriteFooterHome />
  </div>
</template>

<script>
// @ is an alias to /src
import CameriteHeader from "@/components/camerite-header/CameriteHeader.vue";
import CameriteFooterHome from "../components/CameriteFooterHome";

export default {
  name: "Home",
  components: {
    CameriteHeader,
    CameriteFooterHome,
  },
};
</script>

<style scoped lang="scss">
div.home {
  div.home-sections {
    div.bg-film {
      height: calc(100vh - 184px - 64px);
      min-height: 286px;
      width: 100%;
      position: absolute;

      div.for-you {
        height: 100%;
        background-image: url("/landing-page-media/home/Home_VC.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }

      div.for-police {
        height: 100%;
        background-image: url("/landing-page-media/home/Home_Gov.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    div.section {
      height: calc(100vh - 184px - 64px);
      min-height: 286px;

      div {
        padding-bottom: 8px;
      }

      button {
        width: 126px;
        height: 36px;
      }
    }
  }
}

@media (max-width: 991px) {
  div.bg-film {
    height: calc(100vh - 64px) !important;

    div.for-you {
      background-position-x: 75%;
    }

    div {
      background-size: cover;
    }
  }

  div.section {
    height: calc(100vh - 64px) !important;

    div {
      padding-bottom: 0 !important;
    }

    p.revert-text {
      margin-bottom: 4px;
    }
  }
}
</style>
