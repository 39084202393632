<template>
  <div class="camerite-footer">
    <b-container class="px-lg-0">
      <b-row class="pb-3 pt-4 first-row">
        <div class="text-center text-lg-left col-lg-2 pb-4">
          <div>
            <span class="footer-headline">Para você</span>
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="
                $router.push('/vizinhanca-colaborativa').catch(() => {})
              "
              >Vizinhança Colaborativa</span
            >
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="
                $router
                  .push('/vizinhanca-colaborativa/funcionalidades')
                  .catch(() => {})
              "
              >Funcionalidades</span
            >
          </div>
        </div>

        <div class="text-center text-lg-left col-lg-2 pb-4">
          <div>
            <span class="footer-headline">Para polícia</span>
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="
                $router.push('/policia/cidades-inteligentes').catch(() => {})
              "
              >Cidades inteligentes</span
            >
          </div>
        </div>

        <div class="text-center text-lg-left col-lg-2 pb-4">
          <div>
            <span class="footer-headline">Camerite</span>
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="$router.push(solvedCrimesRoute).catch(() => {})"
              >Crimes resolvidos</span
            >
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="$router.push('/encontre').catch(() => {})"
              >Encontre uma Camerite</span
            >
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="$router.push('/sobre').catch(() => {})"
              >Sobre nós</span
            >
          </div>
        </div>

        <div class="text-center text-lg-left col-lg-2 pb-4">
          <div>
            <span class="footer-headline">Ajuda</span>
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="open('https://lab.camerite.com/')"
              >Central de ajuda</span
            >
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="open('https://blog.camerite.com/')"
              >Blog</span
            >
          </div>

          <div>
            <span
              class="footer-item"
              v-on:click="$router.push('/fale-conosco').catch(() => {})"
              >Entre em contato</span
            >
          </div>
        </div>

        <div
          class="col-lg-2 offset-lg-2 pt-2 pt-lg-0 d-flex flex-column align-items-center"
        >
          <div
            class="pb-3 d-flex flex-column align-items-center align-items-lg-end text-center"
          >
            <button
              class="secondary-tangerine"
              v-on:click="
                open('https://contato.camerite.com/seja-um-franqueado')
              "
            >
              Seja um franqueado
            </button>
          </div>

          <div class="d-flex flex-column" id="microsoft-img"></div>
        </div>
      </b-row>

      <b-row class="second-row py-2">
        <div
          class="col-lg-2 pb-3 pb-sm-0 pl-lg-0 d-flex justify-content-lg-start justify-content-center"
          id="footer-logo"
        >
          <b-img
            class="clickable"
            v-on:click="$router.push('/').catch(() => {})"
            src="/landing-page-media/header/logo_pin.png"
            height="30"
          ></b-img>
        </div>

        <div
          class="col-lg-8 pb-2 pb-lg-0 d-flex justify-content-center align-items-center"
          v-if="!mobileView"
        >
          <span class="footer-last text-center" id="footer-terms">
            <span>©2020 Todos os direitos reservados</span> |
            <span
              class="footer-item"
              v-on:click="$router.push('/termos-de-uso').catch(() => {})"
              >Termos de uso</span
            >
            |
            <span
              class="footer-item"
              v-on:click="
                $router.push('/politica-de-privacidade').catch(() => {})
              "
              >Política de privacidade</span
            >
            |
            <span>CNPJ 05.818.541/0001-45</span>
          </span>
        </div>

        <div
          class="col-lg-12 pb-2 d-flex justify-content-center"
          v-if="mobileView"
        >
          <div class="footer-last text-center">
            <span>©2020 Todos os direitos reservados |</span> <br />
            <span
              class="footer-item"
              v-on:click="$router.push('/termos-de-uso').catch(() => {})"
              >Termos de uso</span
            ><span class="px-1">|</span>
            <span
              class="footer-item"
              v-on:click="
                $router.push('/politica-de-privacidade').catch(() => {})
              "
              >Política de privacidade</span
            ><span class="px-1">|</span> <br />
            <span>CNPJ 05.818.541/0001-45</span>
          </div>
        </div>

        <div class="col-lg-2 pr-lg-0">
          <div
            class="d-flex flex-row justify-content-lg-end justify-content-center pb-3 pb-lg-0"
          >
            <div
              class="px-1 clickable"
              v-b-tooltip="'Facebook'"
              v-on:click="open('https://www.facebook.com/cameriteoficial')"
            >
              <b-img
                src="/landing-page-media/footer/footer_icon_facebook.png"
              ></b-img>
            </div>

            <div
              class="px-1 clickable"
              v-b-tooltip="'Instagram'"
              v-on:click="open('https://www.instagram.com/cameriteoficial/')"
            >
              <b-img
                src="/landing-page-media/footer/footer_icon_instagram.png"
              ></b-img>
            </div>

            <div
              class="px-1 clickable"
              v-b-tooltip="'LinkedIn'"
              v-on:click="open('https://www.linkedin.com/company/camerite')"
            >
              <b-img
                src="/landing-page-media/footer/footer_icon_linkedin.png"
              ></b-img>
            </div>

            <div
              class="px-1 pr-lg-0 clickable"
              v-b-tooltip="'YouTube'"
              v-on:click="open('https://www.youtube.com/cameriteoficial')"
            >
              <b-img
                src="/landing-page-media/footer/footer_icon_youtube.png"
              ></b-img>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>

    <WhatsAppContact />
  </div>
</template>

<script>
import WhatsAppContact from "@/components/WhatsAppContact";

export default {
  data() {
    return {
      mobileView: window.innerWidth <= 991,
      routes: [],
      solvedCrimesRoute: "/crimes-resolvidos",
    };
  },
  name: "CameriteFooter",
  components: {
    WhatsAppContact,
  },
  methods: {
    open: function (href) {
      window.open(href, "_blank");
    },
    handleView: function () {
      this.mobileView = window.innerWidth <= 991;
    },
  },
  mounted() {
    if (this.$route.path.includes("/vizinhanca-colaborativa")) {
      this.solvedCrimesRoute = "/vizinhanca-colaborativa/crimes-resolvidos";
    } else if (this.$route.path.includes("/policia")) {
      this.solvedCrimesRoute = "/policia/crimes-resolvidos";
    }

    window.addEventListener("resize", this.handleView);
  },
};
</script>

<style scoped lang="scss">
div.camerite-footer /deep/ .container {
  border-top: 1px solid #bdbdbd;
}

span.footer-headline {
  font-size: 14px;
  font-weight: 900;
  color: $camerite-blue;
}

span.footer-item {
  font-size: 14px;
  color: $camerite-blue;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: $camerite-blue-hover;
  }
}

div.second-row {
  span.footer-last {
    vertical-align: middle;

    span {
      font-size: 14px;
    }
  }
}

#microsoft-img {
  background-image: url("/landing-page-media/footer/Microsoft-Partner-Vertical@2x.png");
  background-size: cover;
  width: 93px;
  height: 68px;
}

button.secondary-tangerine {
  max-width: 150px;
}

@media (max-width: 991px) {
  div.second-row {
    border-top: unset;
  }

  button.secondary-tangerine {
    max-width: 180px;
  }

  #microsoft-img {
    background-image: url("/landing-page-media/footer/microsoft-partner.png");
    background-size: cover;
    width: 138px;
    height: 35.8px;
  }

  #footer-logo {
    display: none;
  }
}
</style>
