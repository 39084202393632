<template>
  <div class="solved-crimes">
    <CameriteHeader />

    <b-container class="px-0 py-5 my-5">
      <b-row class="mx-0">
        <div
          class="col-md-6 d-md-flex flex-md-column justify-content-md-center"
        >
          <div class="py-3">
            <h2>Página não encontrada</h2>
          </div>

          <div class="pb-4">
            <span>Talvez você esteja procurando por:</span>
          </div>

          <div class="">
            <div v-for="route in routes" :key="route.name">
              <a v-bind:href="route.path">
                <u>{{ route.matchingName }}</u>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <b-img fluid src="/landing-page-media/not-found/404.png"></b-img>
        </div>
      </b-row>
    </b-container>

    <CameriteFooter />
  </div>
</template>

<script>
import CameriteHeader from "../components/camerite-header/CameriteHeader";
import CameriteFooter from "../components/CameriteFooter";

export default {
  name: "Functionalities",
  components: {
    CameriteHeader,
    CameriteFooter,
  },
  data() {
    return {
      routes: this.$router.options.routes.filter((route) => route.matchable),
    };
  },
  mounted() {
    this.routes.push({
      path: "https://contato.camerite.com/seja-um-franqueado",
      matchingName: "Seja um franqueado",
    });
  },
};
</script>
