<template>
    <div class="solved-crime">
        <h1 class="pt-5 pb-2">City Câmeras</h1>
        <p class="support-text pb-5">
            O projeto City Câmeras economiza para a cidade de São Paulo 3,6 milhões ao ano com mais de 4.000 câmeras de
            vigilância.
        </p>

        <p class="testimony pb-2">
            “Era um contrato caro para a Prefeitura e agora, com custo zero, temos um número infinitamente maior de
            câmeras de vigilância, com mais tecnologia.”
        </p>

        <p class="bottom-testimony">
            João Doria
        </p>
        <p class="bottom-testimony pb-4">
            Prefeito à época, para a Secretaria de Comunicação de São Paulo
        </p>

        <h3 class="pb-2">Sobre o projeto</h3>
        <p class="pb-3">
            O City Câmeras é um dos serviços de Smart City oferecidos pela Camerite. Seu objetivo é a cobertura urbana
            da cidade de São Paulo através de câmeras de vigilância, pelas quais as forças maiores de segurança podem
            acompanhar as imagens dos patrimônios públicos, escolas, hospitais e avenidas da cidade, garantindo mais
            segurança para a população.
        </p>
        <p class="pb-4">
            Com uma câmera de segurança em cada esquina, conectadas à plataforma Camerite, o sistema possui integração
            das imagens para o Comando da Guarda Civil Metropolitana e compartilhadas com os demais órgãos de segurança
            (Polícia Militar e Civil).
        </p>

        <p class="testimony pb-2">
            “Hoje, quando acontece um crime, a Polícia Civil recebe a informação e faz uma ação no local para achar onde
            estão as câmeras. Agora, damos endereço a essas câmeras de vigilância. A polícia já consegue acessar. A
            finalidade é criar dificuldade para a prática do crime na cidade.”
        </p>
        <p class="bottom-testimony">
            Depoimento do policial responsável pela ação
        </p>
        <p class="bottom-testimony pb-5">
            Secretário de Segurança Urbana para a Secretaria de Comunicação de São Paulo
        </p>
    </div>
</template>

<script>
    export default {
        name: "SC1"
    }
</script>

<style scoped lang="scss">
    p {
        margin-bottom: unset;
    }

    p.testimony {
        font-size: 18px;
        color: $camerite-blue;
    }

    p.bottom-testimony, span.bottom-testimony {
        font-size: 14px;
        font-weight: bold;
    }
</style>
