<template>
    <div class="see-how-easy-container w-100 px-2 py-5 px-lg-5 my-5">
        <h1 class="text-center">Viu como é fácil?</h1>

        <div class="px-2 px-lg-5 py-2" v-if="!text">
            <p class="text-center my-0">Com a Camerite todos podem ter seu sistema de segurança e cuidar do que mais
                importa.</p>
            <p class="text-center">Comece agora mesmo uma
                Vizinhança Colaborativa em sua rua!</p>
        </div>
        <div class="px-2 px-lg-5 py-2" v-else-if="text === 'smart-cities'">
            <p class="text-center my-0">Fale com a gente e entenda como você pode tornar sua cidade mais segura com a Camerite.</p>
        </div>

        <div class="d-flex justify-content-center">
            <button class="tangerine" v-on:click="$router.push('/encontre').catch(()=>{});">Encontre uma Camerite</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SeeHowEasyItIs",
        props: {
            text: String
        }
    }
</script>

<style scoped lang="scss">
    div.see-how-easy-container {
        background-color: $camerite-dark-blue-grey;
        border-radius: 11px;

        h1 {
            color: white;
        }

        p {
            color: white;
            font-size: 20px;
            line-height: 1.4;
        }

        /*@media (max-width: @screen-sm) {*/
        /*    body{font-size: 14px;}*/
        /*}*/
    }
</style>
