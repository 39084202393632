<template>
    <div class="camerite-header">
        <WebHeader :routes="this.routes" v-if="mobileView === false"/>
        <MobileHeader :routes="this.routes" v-if="mobileView === true"/>
    </div>
</template>

<script>
    import Route from '../../models/route';
    import WebHeader from "./WebHeader";
    import MobileHeader from "./MobileHeader";

    export default {
        data() {
            return {
                mobileView: window.innerWidth <= 1199,
                routes: [],
                type: ''
            }
        },
        name: 'CameriteHeader',
        components: {
            WebHeader, MobileHeader
        },

        methods: {
            buildHeader: function () {
                if(this.$route.path.includes('/vizinhanca-colaborativa')) {
                    this.routes = [
                        new Route('Vizinhança Colaborativa', '/vizinhanca-colaborativa'),
                        new Route('Funcionalidades', '/vizinhanca-colaborativa/funcionalidades'),
                        new Route('Crimes Resolvidos', '/vizinhanca-colaborativa/crimes-resolvidos')
                    ]
                } else if (this.$route.path.includes('/policia')) {
                    this.routes = [
                        new Route('Cidades Inteligentes', '/policia/cidades-inteligentes'),
                        new Route('Crimes Resolvidos', '/policia/crimes-resolvidos')
                    ]
                }
            },
            handleView: function () {
                this.mobileView = window.innerWidth <= 1199;
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleView);
            this.buildHeader();
        }
    }
</script>
