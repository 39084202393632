<template>
    <b-jumbotron fluid
                 class="camerite-jumbotron"
                 v-bind:class="{'mobileContainer': isTrueMobile}"
                 v-bind:style="{
        backgroundImage: 'url(' + finalImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover' ,
        backgroundPositionX: isTrueMobile ? '100%' : pos,
        backgroundPositionY: isTrueMobile ? '100%' : '25%',
        display: isTrueMobile ? 'flex' : 'block',
        alignItems: mobileTextPos === 'bottom' ? 'flex-end' : 'flex-start'
    }">
        <b-container class="px-0">
            <div class="col-lg-6 col-md-7 col-sm-12 px-0 py-lg-5">
                <div class="pb-3" v-if="top && !isTrueMobile">
                    <span class="font-weight-bold" v-bind:class="{'text-white': textWhite}">{{ top }}</span>
                </div>

                <div class="pb-3" v-if="main">
                    <h1 v-bind:class="{'revert-text': isTrueMobile, 'text-white': textWhite}">{{ main }}</h1>
                </div>

                <div v-if="bottom">
                    <span class="support-text" v-bind:class="{'revert-text': isTrueMobile, 'text-white': textWhite}">{{ bottom }}</span>
                </div>
            </div>
        </b-container>
    </b-jumbotron>
</template>

<script>
export default {
    data() {
        return {
            mobileSmView: window.innerWidth <= 767,
            finalImg: '',
            isTrueMobile: false
        }
    },
    name: "CameriteJumbotron",
    props: {
        top: String,
        main: String,
        bottom: String,
        img: String,
        pos: {
            type: String,
            default: '100%'
        },
        mobileImg: String,
        mobileTextPos: {
            type: String,
            default: 'bottom'
        },
        textWhite: Boolean
    },
    methods: {
        handleView: function () {
            this.mobileSmView = window.innerWidth <= 767;
            this.finalImg = this.mobileSmView && this.mobileImg ? this.mobileImg : this.img;
            this.isTrueMobile = this.mobileSmView && this.mobileImg;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleView);
        this.handleView();
    }
}
</script>

<style scoped lang="scss">

.mobileContainer {
    height: 85vh;
}

</style>
