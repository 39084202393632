import Vue from 'vue'
import App from './App'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from './router'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import AwesomeMask from 'awesome-mask'
import VueYouTubeEmbed from 'vue-youtube-embed'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(faArrowLeft, faArrowRight, faWhatsapp)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

Vue.directive('mask', AwesomeMask);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueYouTubeEmbed);

Vue.config.productionTip = false

extend('email', {
  ...email, message: 'Este e-mail é inválido'
});
extend('required', {
  ...required,
  message: 'Este campo é obrigatório'
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
