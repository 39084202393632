import { render, staticRenderFns } from "./IconAttention.vue?vue&type=template&id=53ad76db&scoped=true&"
var script = {}
import style0 from "./IconAttention.vue?vue&type=style&index=0&id=53ad76db&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ad76db",
  null
  
)

export default component.exports