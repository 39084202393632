<template>
    <div class="privacy">
        <CameriteHeader />

        <b-container class="py-5">
            <div class="pb-4">
                <div class="pb-3">
                    <h2>Termos de uso do Software Camerite</h2>
                </div>

                <div>
                    <p class="font-weight-bold">Última atualização feita em 28 de maio de 2024.</p>
                </div>
            </div>

            <div class="pb-4">
                <div>
                    <b class="cap-text">1. ACEITAÇÃO DO TERMO DE SERVIÇO</b><br>
                    <p>Este documento de <b class="bold-text">Termos de Uso</b> (“TERMO”) é um acordo legal entre você,
                        pessoa física ou jurídica, e
                        a Camerite Sistemas S.A (“Camerite”), uma empresa regularmente registrada no Brasil, inscrita no
                        CNPJ sob o nº 05.818.541/0001-45, com sede na Av. Santos Dumont, 935, térreo, bairro: Santo Antônio,
                        CEP: 89.218-105, cidade de Joinville, estado de Santa Catarina. Ao acessar as páginas ou quaisquer
                        serviços da Camerite e, ao clicar em “Eu aceito”, você manifesta a sua concordância com estes termos
                        e condições (“<b class="bold-text">Termos de Uso</b>”), assim como concorda com a política de
                        privacidade da Camerite,
                        localizado no endereço eletrônico
                        <a href="https://camerite.com/politica-de-privacidade">www.camerite.com/politica-de-privacidade</a>,
                        incorporado ao presente como referência. Se você não concordar com algum destes termos, não deverá
                        utilizar o <b class="highlight-text">"Serviço"</b>.
                    </p>

                    <p>Quanto à capacidade para aceitar os <b class="bold-text">Termos de Uso</b>, você afirma ser maior de
                        18 (dezoito) anos ou ser
                        menor emancipado, ou estar de posse de autorização legal dos pais ou de tutores, e plenamente capaz
                        de consentir com os termos, condições, obrigações, afirmações, representações e garantias descritas
                        nestes <b class="bold-text">Termos de Uso</b>, obedecê-los e cumpri-los. Se for utilizar o <b
                            class="highlight-text">"Serviço"</b> em nome de uma empresa,
                        você declara para a Camerite que tem autoridade para vincular o negócio e que sua aceitação destes
                        Termos de Uso será tratada como aceitação por essa empresa, conforme cadastro realizado no
                        <b class="highlight-text">"Serviço"</b>.
                    </p>

                    <p class="pb-4">Ao utilizar o <b class="highlight-text">"Serviço"</b>, mesmo que parcialmente ou a
                        título de teste, você estará
                        vinculado ao presente “TERMO”, concordando com suas disposições, principalmente em relação ao
                        CONSENTIMENTO PARA O ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO às
                        informações pela Camerite, necessárias para a integral execução das funcionalidades ofertadas pelo
                        <b class="highlight-text">"Serviço"</b>.
                    </p>

                    <b class="cap-text">2. DESCRIÇÃO DO SERVIÇO</b>
                    <p>Estes <b class="bold-text">Termos de Uso</b> se aplicam a todos os usuários do <b
                            class="highlight-text">"Serviço"</b>, inclusive os usuários que também
                        contribuem com Conteúdo no <b class="highlight-text">"Serviço"</b>. Conteúdo Inclui texto,
                        programas, scripts, gráficos, fotos,
                        sons, músicas, vídeos, combinações audiovisuais, recursos interativos e outros materiais que você
                        pode ver, ter acesso, ou contribuir para o <b class="highlight-text">"Serviço"</b>. O <b
                            class="highlight-text">"Serviço"</b> inclui todos os aspectos da
                        Camerite, inclusive, mas não se limita a todos os produtos, programas e serviços oferecidos pela
                        Camerite e outros aplicativos.</p>

                    <p>Você aceita sua única responsabilidade por qualquer material, incluindo, mas não restrito a dados
                        gráficos, fotográficos e vídeos fornecidos à Camerite ou publicados na internet usando a Camerite,
                        que está sujeito a direitos autorais ou aos regulamentos locais do país do uso ou regulamentos
                        internacionais.</p>

                    <p>O <b class="highlight-text">"Serviço"</b> pode conter links para websites de terceiros que não
                        pertencem, nem são controlados pela
                        Camerite. A Camerite não tem controle e não assume responsabilidade pelo conteúdo, políticas de
                        privacidade ou práticas de qualquer site de terceiros. Ao usar o <b
                            class="highlight-text">"Serviço"</b>, você expressamente
                        isenta a Camerite de toda e qualquer responsabilidade derivada do seu uso de qualquer site de
                        terceiros. Consequentemente, o aconselhamos a ter ciência ao utilizar o <b
                            class="highlight-text">"Serviço"</b>, ler os termos e
                        condições e a política de privacidade de todos os outros sites que você visitar.</p>

                    <p>Você também entende e concorda que o <b class="highlight-text">"Serviço"</b> pode incluir certas
                        comunicações da Camerite, como
                        avisos de serviço, mensagens administrativas e o boletim de notícias Camerite, que essas
                        comunicações são consideradas parte da Camerite e que você não poderá optar por não recebê-las,
                        conforme relacionados na política de privacidade. A menos que seja explicitamente estabelecido o
                        contrário, quaisquer novos recursos que aumentem ou aprimorem o <b
                            class="highlight-text">"Serviço"</b> atual estarão sujeitos aos
                        <b class="bold-text">Termos de Uso</b>. Você entende e concorda que o <b
                            class="highlight-text">"Serviço"</b> é fornecido “como está” e que a Camerite não
                        assume qualquer responsabilidade pela correção de tempo, exclusão, entrega incorreta ou falha em
                        armazenar quaisquer configurações de comunicação ou personalização do usuário. Você é responsável
                        por obter acesso ao <b class="highlight-text">"Serviço"</b> e esse acesso pode envolver taxas de
                        terceiros (como o provedor de
                        serviços de internet). Você é responsável por essas taxas, incluindo as taxas associadas ao
                        <b class="highlight-text">"Serviço"</b>. Além disso, você deve fornecer e é responsável por todo o
                        equipamento necessário para
                        acessar o <b class="highlight-text">"Serviço"</b>.
                    </p>

                    <p>Sob nenhuma circunstância o armazenamento e a transmissão de materiais pornográficos de qualquer
                        forma serão permitidos através dos servidores da Camerite. Você reconhece que o serviço só pode ser
                        usado para propósitos legais. Qualquer informação incluindo, mas não restrita a gráfico, imagem,
                        fotografia, texto em violação de qualquer regulamento internacional incluindo, mas não restrito a
                        material que seja obsceno, indecente, considerado ilegal, ameaçador, prejudicial (incluindo qualquer
                        transferência de vírus de computador), direitos autorais, segredo comercial, é proibido se o cliente
                        estava ciente do conteúdo, material e/ou das leis pertinentes ao material.</p>

                    <p>A Camerite se reserva o direito de limitar a transferência de dados, se necessário, por qualquer
                        motivo.</p>

                    <p>Você reconhece que será o único responsável por qualquer violação da lei internacional com relação ao
                        carregamento remoto de informações de qualquer tipo nos servidores/computadores da Camerite, para
                        visualização ou download, ou por terceiros. A Camerite manterá o direito de suspender e/ou rescindir
                        qualquer serviço remoto que considerem estar em violação do Direito Internacional ou que seja de
                        natureza prejudicial, ameaçadora ou julgada como incluindo, mas não se restringindo ao material que
                        é obsceno, indecente, injurioso e/ou que violem direitos autorais e/ou qualquer lei ou regulamento
                        internacional.</p>

                    <p>Você reconhece sua única responsabilidade em relação a qualquer reclamação de terceiros alegando
                        qualquer violação de direitos de qualquer tipo devido à transmissão de qualquer informação
                        visualizada pelo usuário e deve incluir qualquer violação de direitos sob a lei internacional e/ou
                        regulamento e como tal, concorda em pagar à Camerite quaisquer custos incorridos na defesa de
                        qualquer ação movida por uma terceira parte decorrente de tais reclamações.</p>

                    <p>Você mantém todos os direitos de propriedade sobre seu Conteúdo. Entretanto, ao enviar o Conteúdo à
                        Camerite, você, pelo presente, cede à Camerite licença mundial, não exclusiva, isenta de royalties,
                        passível de ser sublicenciada e transferida, para usar, reproduzir, distribuir, preparar trabalhos
                        derivados, exibir e executar o Conteúdo em conexão com o <b class="highlight-text">"Serviço"</b>,
                        inclusive, mas sem se limitar a
                        atividades de promoção e redistribuição parcial ou total do <b class="highlight-text">"Serviço"</b>
                        (e trabalhos derivados) em
                        qualquer formato de mídia e através de qualquer canal de mídia. Você também cede a todos os usuários
                        do <b class="highlight-text">"Serviço"</b> uma licença não-exclusiva para acessar o seu Conteúdo por
                        meio do <b class="highlight-text">"Serviço"</b>, e para
                        usar, reproduzir, distribuir, exibir e executar tal Conteúdo conforme permitido pelas
                        funcionalidades do <b class="highlight-text">"Serviço"</b> e de acordo com estes Termos de Uso. As
                        licenças acima cedidas por Você
                        irão encerrar dentro de um tempo comercialmente razoável após a remoção ou exclusão do <b
                            class="highlight-text">"Serviço"</b>.
                    </p>

                    <p class="pb-4">A Camerite não endossa qualquer conteúdo enviado para o <b
                            class="highlight-text">"Serviço"</b> por qualquer usuário
                        ou licenciante ou qualquer opinião, recomendação ou conselho ali expresso e a Camerite renuncia
                        expressamente toda e qualquer responsabilidade ligada ao Conteúdo. A Camerite não permite atividades
                        que violem os direitos autorais, nem os direitos de propriedade intelectual em seu <b
                            class="highlight-text">"Serviço"</b> e
                        removerá todo o Conteúdo quando devidamente notificado de que tal Conteúdo infringe o direito de
                        propriedade intelectual de qualquer outra pessoa. A Camerite se reserva o direito de remover o
                        Conteúdo sem prévio aviso.</p>

                    <b class="cap-text">3. SUAS OBRIGAÇÕES DE REGISTRO</b><br>
                    <p class="pb-4">Em consideração ao seu uso do <b class="highlight-text">"Serviço"</b>, você declara que
                        é maior de idade para formar
                        um contrato vinculativo e não é uma pessoa impedida de receber serviços sobre a jurisdição
                        aplicável. Você também concorda em: (a) fornecer informações verdadeiras, precisas, atuais e
                        completas sobre você, conforme solicitado pelo registro do <b class="highlight-text">"Serviço"</b>
                        ou outros formulários (os
                        "Dados de Registro") e (b) manter e atualizar prontamente os Dados de Registro para mantê-los
                        verdadeiros, precisos, atuais e completos. Se você fornecer qualquer informação que seja falsa,
                        imprecisa, não atual ou incompleta, ou se a Camerite tiver motivos razoáveis para suspeitar que
                        essas informações são falsas, imprecisas, não atualizadas ou incompletas, a Camerite tem o direito
                        de suspender ou encerrar sua conta e recusar todo e qualquer uso atual ou futuro do <b
                            class="highlight-text">"Serviço"</b> (ou
                        qualquer parte dele).</p>

                    <b class="cap-text">4. CONTA, SENHA E SEGURANÇA</b><br>
                    <p class="pb-4">Você pode receber uma senha e designação da conta após completar o processo de registro
                        do <b class="highlight-text">"Serviço"</b>. Você é responsável por manter a confidencialidade da
                        senha e da conta e é totalmente
                        responsável por todas as atividades que ocorrem sob sua senha ou conta. Você concorda em notificar
                        imediatamente a Camerite de qualquer uso não autorizado de sua senha ou conta ou qualquer outra
                        violação de segurança, e (b) garantir que você saia da sua conta no final de cada sessão. A Camerite
                        não pode e não será responsável por qualquer perda ou dano decorrente do seu incumprimento nesta
                        Seção 5.</p>

                    <b class="cap-text">5. CONDUTA DO USUÁRIO</b><br>
                    <p>Você entende que todas as informações, dados, textos, vídeos, fotografias, gráficos, mensagens, tags
                        ou outros materiais ("Conteúdo"), publicados ou transmitidos de forma privada, são de exclusiva
                        responsabilidade da pessoa de quem esse Conteúdo se originou. Isso significa que você, e não a
                        Camerite, é inteiramente responsável por todo o conteúdo que você carrega, pública, transmite ou
                        disponibiliza de outro modo através do <b class="highlight-text">"Serviço"</b>. A Camerite não
                        controla o Conteúdo postado pelo
                        uso do <b class="highlight-text">"Serviço"</b> e, como tal, não garante a precisão, integridade ou
                        qualidade desse conteúdo. Você
                        entende que, ao usar o <b class="highlight-text">"Serviço"</b>, você pode estar exposto a Conteúdo
                        que é ofensivo, indecente ou
                        censurável. Sob nenhuma circunstância a Camerite será responsável, de qualquer forma, por qualquer
                        conteúdo, incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer Conteúdo.</p>

                    <p>Você concorda em não usar o <b class="highlight-text">"Serviço"</b> para:<br>
                        1. carregar, publicar, enviar por e-mail, transmitir ou disponibilizar qualquer Conteúdo que seja
                        ilegal, prejudicial, ameaçador, abusivo, assediante, tortuoso, difamatório, vulgar, obsceno,
                        invasivo da privacidade de outrem, odioso ou racial, etnicamente ou de outra forma censurável;<br>
                        2. prejudicar os menores de qualquer forma;<br>
                        3. personificar qualquer pessoa ou entidade, incluindo, mas não limitado a, um oficial, guia ou host
                        da Camerite, ou declarar falsamente ou de outra forma deturpar sua afiliação com uma pessoa ou
                        entidade;<br>
                        4. forjar cabeçalhos ou manipular identificadores de forma a disfarçar a origem de qualquer Conteúdo
                        transmitido através do <b class="highlight-text">"Serviço"</b>;<br>
                        5. carregar, publicar, enviar, transmitir ou disponibilizar qualquer Conteúdo que não tenha o
                        direito de disponibilizar sob qualquer lei ou em relações contratuais (como informações
                        privilegiadas, informações confidenciais ou divulgadas como parte de relações de trabalho ou em
                        acordos de não divulgação);<br>
                        6. carregar, publicar, enviar, transmitir ou disponibilizar qualquer Conteúdo que viole qualquer
                        patente, marca registrada, segredo comercial, direitos autorais ou outros direitos de propriedade
                        ("Direitos") de qualquer parte;<br>
                        7. carregar, publicar, enviar, transmitir ou disponibilizar qualquer publicidade não solicitada ou
                        não autorizada, materiais promocionais, "lixo eletrônico", "spam", "esquemas de pirâmide" ou
                        qualquer outra forma de solicitação, exceto nessas áreas que são designados para tal finalidade;
                        8. carregar, publicar, enviar, transmitir ou disponibilizar qualquer material que contenha vírus de
                        software ou qualquer outro código de computador, arquivos ou programas projetados para interromper,
                        destruir ou limitar a funcionalidade de qualquer software ou hardware ou equipamento de
                        telecomunicações;<br>
                        9. interferir ou interromper o <b class="highlight-text">"Serviço"</b> ou servidores ou redes
                        conectados ao <b class="highlight-text">"Serviço"</b>, ou
                        desobedecer quaisquer requisitos, procedimentos, políticas ou regulamentos das redes conectadas ao
                        <b class="highlight-text">"Serviço"</b>;<br>
                        10. violar intencionalmente ou involuntariamente qualquer lei local, estadual, nacional ou
                        internacional aplicável, e quaisquer regulamentos que tenham força de lei;<br>
                        11. perseguir ou de outra forma assediar outro;<br>
                        12. coletar ou armazenar dados pessoais sobre outros usuários em conexão com a conduta proibida e
                        atividades estabelecidas nos parágrafos acima.
                    </p>

                    <p>Você reconhece que a Camerite pode ou não pré-visualizar o conteúdo, mas a Camerite e seus designados
                        devem ter o direito (mas não a obrigação), a seu exclusivo critério, de pré-visualizar, recusar ou
                        remover qualquer Conteúdo que esteja disponível através do <b class="highlight-text">"Serviço"</b>.
                        Sem limitar o que precede, a
                        Camerite e seus designados devem ter o direito de remover qualquer Conteúdo que viole os <b
                            class="bold-text">Termos de
                            Uso</b> ou de outra forma censurável. Você concorda que deve avaliar e suportar todos os riscos
                        associados ao uso de qualquer Conteúdo, incluindo qualquer dependência da precisão, integridade ou
                        utilidade de tal conteúdo.</p>

                    <p>Você reconhece, aceita e concorda que a Camerite pode acessar, preservar e divulgar as informações e
                        o Conteúdo da sua conta, se necessário para fazê-lo por lei ou de boa fé, de que tal preservação ou
                        divulgação de acesso é razoavelmente necessária para: (a) cumprir o processo legal ; (b) fazer
                        cumprir os <b class="bold-text">Termos de Uso</b>; (c) responder às alegações de que qualquer
                        conteúdo viola os direitos de
                        terceiros; (d) responder aos pedidos de atendimento feitos por autoridades policiais; ou (e)
                        proteger os direitos, propriedade ou segurança pessoal da Camerite, seus usuários e o público.</p>

                    <p>Você entende que o processamento e transmissão técnica do <b class="highlight-text">"Serviço"</b>,
                        incluindo seu conteúdo, podem
                        envolver (a) transmissões em várias redes; e (b) mudanças para conformidade e adaptação aos
                        requisitos técnicos de conexão de redes ou dispositivos.</p>

                    <p class="pb-4">Você entende que o <b class="highlight-text">"Serviço"</b> e o software incorporados no
                        <b class="highlight-text">"Serviço"</b> podem incluir
                        componentes de segurança que permitem que os materiais digitais sejam protegidos e que o uso desses
                        materiais esteja sujeito às regras de uso estabelecidas pela Camerite e/ou provedores de conteúdo
                        que fornecem conteúdo ao <b class="highlight-text">"Serviço"</b>. Você não pode tentar anular ou
                        contornar nenhuma das regras de
                        uso incorporadas no <b class="highlight-text">"Serviço"</b>. Qualquer reprodução, publicação,
                        distribuição ou exibição pública não
                        autorizada dos materiais fornecidos no <b class="highlight-text">"Serviço"</b>, no todo ou em parte,
                        é estritamente proibida.
                    </p>

                    <b class="cap-text">6. REMUNERAÇÃO E FORMA DE PAGAMENTO</b><br>
                    <p>Você deverá pagar à Camerite o setup (“Setup”) e uma assinatura de acordo com o plano de <b
                            class="highlight-text">"Serviço"</b>
                        escolhido e de acordo com a periodicidade definida entre as opções de pagamento disponibilizadas a
                        Você no ato da contratação (“Assinatura”). Você poderá, ainda, contratar funcionalidades adicionais
                        que serão cobradas durante o período que as mesmas estiverem disponíveis à você.</p>

                    <p>A falta de pagamento de quaisquer valores nas respectivas datas de vencimento não acarretará na
                        rescisão automática do Termo, mas causará a suspensão de acesso ao <b
                            class="highlight-text">"Serviço"</b> até que as pendências
                        financeiras tenham sido regularizadas. O acesso ao <b class="highlight-text">"Serviço"</b> somente
                        será restabelecido após a
                        identificação pela Camerite do pagamento integral de todos os valores devidos enquanto o mesmo
                        estiver suspenso. Esse prazo dependerá dos meios de pagamento e respectivo prazo de compensação.
                        Caso você não resolva a pendência financeira no prazo de 30 (trinta) dias contados do vencimento do
                        valor não pago, a Camerite se reserva o direito de rescindir o presente Termo e apagar de forma
                        definitiva e irrecuperável todas as informações por você lançadas no <b
                            class="highlight-text">"Serviço"</b> e/ou que por ventura
                        estejam armazenadas no <b class="highlight-text">"Serviço"</b>, sem aviso prévio adicional.</p>

                    <p>O valor do Setup e da Assinatura mensal estão com tributos inclusos. Na ocorrência de criação de
                        novos tributos e/ou majoração de alíquotas, os valores do Setup e/ou da Assinatura serão atualizados
                        para contemplar tais majorações, na medida em que tais tributos passarem a incidir sobre o
                        faturamento da Camerite.</p>

                    <p class="pb-4">De acordo com o plano de <b class="highlight-text">"Serviços”</b> contratado por você, a
                        Camerite manterá o backup do
                        Conteúdo de streaming de vídeo de acordo com a periodicidade definida no Plano de <b
                            class="highlight-text">"Serviços”</b>.
                        Ultrapassado a periodicidade de backup do Plano de <b class="highlight-text">"Serviço"</b>
                        contratado ou mesmo em caso de término
                        ou rescisão deste Termo, a Camerite eliminará de sua base de dados todo o Conteúdo, não podendo
                        recuperá-los. A Camerite manterá somente os dados necessários para fins de comprovação legal do
                        relacionamento estabelecido entre as partes, por prazo não superior ao necessário.</p>

                    <b class="cap-text">7. CONTRIBUIÇÕES PARA CAMERITE</b><br>
                    <p class="pb-4">Ao enviar ideias, sugestões, documentos e/ou propostas ("Contribuições") para a Camerite
                        através de suas páginas ou e-mails de sugestão ou feedback, você reconhece e concorda que: (a) suas
                        Contribuições não contêm informações confidenciais ou de propriedade; (b) a Camerite não está sob
                        nenhuma obrigação de confidencialidade, expressa ou implícita, com relação às Contribuições; (c) a
                        Camerite deve ter o direito de usar ou divulgar (ou escolher não usar ou divulgar) tais
                        Contribuições para qualquer finalidade, de qualquer forma, em qualquer mídia mundial; (d) a Camerite
                        pode ter algo semelhante às Contribuições já em consideração ou em desenvolvimento; (e) suas
                        Contribuições tornam-se automaticamente propriedade da Camerite sem qualquer obrigação da Camerite
                        para você.</p>

                    <b class="cap-text">8. INDENIZAÇÃO</b><br>
                    <p class="pb-4">Você concorda em indenizar e manter a Camerite e suas subsidiárias, afiliadas, oficiais,
                        agentes, funcionários, parceiros e licenciadores inofensivos de qualquer reclamação ou demanda,
                        incluindo honorários advocatícios razoáveis, feitos por terceiros em decorrência ou decorrentes do
                        Conteúdo que você envie, publique, transmita ou disponibilize através do <b
                            class="highlight-text">"Serviço"</b>, uso do serviço,
                        sua conexão com o <b class="highlight-text">"Serviço"</b>, sua violação dos <b
                            class="bold-text">Termos de Uso</b> ou sua violação de quaisquer direitos de
                        outro.</p>

                    <b class="cap-text">9. MODIFICAÇÃO E ENCERRAMENTO DO SERVIÇO</b><br>
                    <p>A Camerite reserva-se o direito, a qualquer momento e de tempos em tempos, de modificar ou
                        interromper, temporariamente ou permanentemente, o <b class="highlight-text">"Serviço"</b> (ou
                        qualquer parte dele) com ou sem
                        aviso prévio. Você concorda que a Camerite não será responsável perante você ou qualquer terceiro
                        por qualquer modificação, suspensão ou descontinuação do <b class="highlight-text">"Serviço"</b>.
                    </p>

                    <p class="pb-4">Você concorda que a Camerite pode, em determinadas circunstâncias e sem aviso prévio,
                        encerrar imediatamente sua conta na Camerite em decorrência de (a) violações dos Termos de Uso,
                        política de privacidade ou outros acordos ou diretrizes incorporados, (b) pedidos por parte de
                        autoridades policiais ou outras agências governamentais, (c) pedido por você a eliminação de contas
                        iniciadas, (d) descontinuação ou modificação de material no <b class="highlight-text">"Serviço"</b>
                        (ou qualquer parte dele), (e)
                        problemas técnicos ou de segurança inesperados, (f) períodos prolongados de inatividade, (g)
                        participação por você em fraudes ou atividades ilegais, e/ou (h) não pagamento de quaisquer taxas
                        devidas por você em conexão com os <b class="highlight-text">"Serviços”</b>. A rescisão da sua conta
                        na Camerite inclui (a) a
                        remoção do acesso a todas as ofertas dentro do <b class="highlight-text">"Serviço"</b>, (b) a
                        exclusão de sua senha e todas as
                        informações, arquivos e conteúdo relacionados associados ou dentro de sua conta (ou qualquer parte
                        dela), e (c) a restrição de uso adicional do <b class="highlight-text">"Serviço"</b>. Além disso,
                        você concorda que todas as
                        rescisões devem ser feitas no exclusivo critério da Camerite e que a Camerite não será responsável
                        perante você ou qualquer terceiro por qualquer rescisão de sua conta em decorrência dos motivos
                        apresentados acima.</p>

                    <b class="cap-text">10. DIREITOS DE PROPRIEDADE DA CAMERITE</b><br>
                    <p>Você reconhece e concorda que o <b class="highlight-text">"Serviço"</b> e qualquer software
                        necessário usado em conexão com o
                        <b class="highlight-text">"Serviço"</b> ("Software") contém informações proprietárias e
                        confidenciais que estão protegidas pela
                        propriedade intelectual aplicável e outras leis. Você ainda reconhece e concorda que o conteúdo
                        contido nos anúncios ou informações do patrocinador apresentado por você através do <b
                            class="highlight-text">"Serviço"</b> ou por
                        anunciantes é protegido por direitos autorais, marcas comerciais, marcas de serviço, patentes ou
                        outros direitos e leis de propriedade. Exceto conforme expressamente permitido pela lei aplicável ou
                        autorizado pela Camerite ou anunciantes. Você concorda em não modificar, alugar, arrendar,
                        emprestar, vender, distribuir ou criar obras derivadas com base no <b
                            class="highlight-text">"Serviço"</b> ou no Software, no todo
                        ou em parte, exceto até o limite aprovado.
                    </p>

                    <p class="pb-4">A Camerite concede-lhe um direito e uma licença pessoal, não transferível e não
                        exclusiva para usar o <b class="highlight-text">"Serviço"</b>, desde que você não (e não permita que
                        terceiros) copie, modifique,
                        crie um trabalho derivado, engenharia reversa, faça a montagem reversa ou tente detectar qualquer
                        código-fonte do <b class="highlight-text">"Serviço"</b>. Você concorda em não modificar o <b
                            class="highlight-text">"Serviço"</b> de qualquer maneira ou forma,
                        nem usar versões modificadas do <b class="highlight-text">"Serviço"</b>, incluindo (sem limitação)
                        com a finalidade de obter
                        acesso não autorizado ao <b class="highlight-text">"Serviço"</b>. Você concorda em não acessar o <b
                            class="highlight-text">"Serviço"</b> por qualquer outro meio
                        que não seja através da interface fornecida pela Camerite para uso ou acesso ao <b
                            class="highlight-text">"Serviço"</b>.</p>

                    <b class="cap-text">11. RENÚNCIA DE GARANTIAS</b><br>
                    <p class="pb-4">Você expressamente entende e aceita que:<br>
                        1. O uso do serviço é por seu único risco. o serviço é fornecido "tal como está" e "como
                        disponível". a camerite e suas subsidiárias, afiliadas, oficiais, empregados, agentes, parceiros e
                        licenciantes excluem expressamente todas as garantias de qualquer tipo, expressas ou implícitas,
                        incluindo, mas não se limitando às garantias implícitas de comercialização, aptidão para um
                        propósito específico e não violação.<br>
                        2. A camerite e suas subsidiárias, afiliadas, oficiais, empregados, agentes, parceiros e
                        licenciantes não garantem que: (i) O serviço encontrará os seus requisitos; (ii) O serviço será
                        ininterrupto, temporário, seguro ou livre de erros; (iii) Os resultados que podem ser obtidos do uso
                        do serviço será preciso ou confiáveis; (iv) A qualidade de qualquer produto, serviço, informação ou
                        outros materiais comprados ou obtidos por você, através do serviço encontrarão as suas expectativas;
                        e (v) Quaisquer erros no software serão corrigidos.<br>
                        3. Qualquer material descarregado ou obrigado de outra forma através do uso do serviço é acessado à
                        sua própria descrição e riscos, e será responsável por qualquer dano ao seu sistema de computador ou
                        perda de dados que resultem do descarregamento de qualquer tipo de material.<br>
                        4. Nenhum aviso ou informação, seja oral ou escrita, obtido por você da camerite ou através ou a
                        partir do serviço, criará qualquer garantia não expressamente indicada nos <b
                            class="bold-text">Termos de Uso</b>.</p>

                    <b class="cap-text">12. LIMITAÇÃO DE RESPONSABILIDADE</b><br>
                    <p class="pb-4">Você expressamente entende e concorda que a camerite e suas subsidiárias, afiliadas,
                        oficiais, empregados, agentes, parceiros e licenciantes não serão responsáveis por quaisquer danos
                        indiretos, incidentais, especiais, consequentes ou exemplares, incluindo, mas não se limitando a
                        danos por perda de lucros, boas práticas de uso, dados ou outras perdas intangíveis (mesmo que a
                        camerite tenha sido avisada da possibilidade de tais danos), resultando de: (i) o uso ou a
                        incapacidade de usar o serviço; (ii) o custo de contratos de mercadorias substitutas e serviços
                        resultantes de quaisquer mercadorias, dados, informações ou serviços adquiridos ou obtidos ou
                        mensagens recebidas ou transações entradas através ou a partir do serviço; (iii) acesso não
                        autorizado ou alteração de suas transmissões ou dados; (iv) declarações ou conduta de qualquer
                        terceiro no serviço. qualquer indenização da camerite à você sob esses termos ficará limitada a 3
                        (três) vezes o valor da última assinatura paga.</p>

                    <b class="cap-text">13. AVISO</b><br>
                    <p class="pb-4">A Camerite pode fornecer avisos, incluindo aqueles relativos a alterações nos Termos de
                        Uso, entre outros, e-mail, correio regular, postagens no <b class="highlight-text">"Serviço"</b> ou
                        outros meios razoáveis agora
                        conhecidos ou desenvolvidos a seguir.</p>

                    <b class="cap-text">14. INFORMAÇÕES DA MARCA REGISTRADA</b><br>
                    <p class="pb-4">A Camerite, o logotipo Camerite, marcas registradas e marcas de serviço e outros
                        logotipos Camerite e nomes de produtos e serviços são marcas registradas da Camerite, sem a
                        permissão prévia da Camerite você concorda em não exibir ou usar de forma alguma as marcas da
                        Camerite.</p>

                    <b class="cap-text">15. AVISO E PROCEDIMENTO PARA FAZER RECLAMAÇÕES DE DIREITOS AUTORAIS OU INTELECTUAIS</b><br>
                    <p><b>INFRAÇÃO DA PROPRIEDADE</b><br>
                        A Camerite respeita a propriedade intelectual de terceiros e pedimos aos nossos usuários que façam o
                        mesmo. A Camerite pode, em circunstâncias apropriadas e a seu critério, desativar e/ou encerrar as
                        contas de usuários que podem ser infratores repetidos. Se você acredita que seu trabalho foi copiado
                        de forma que constitui violação de direitos autorais ou seus direitos de propriedade intelectual
                        tenham sido violados de outra forma, forneça ao agente de direitos autorais da Camerite as seguintes
                        informações:</p>

                    <p>1. uma assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário do direito
                        autoral ou outro interesse de propriedade intelectual;<br>
                        2. uma descrição do trabalho protegido por direitos autorais ou outra propriedade intelectual que
                        você reivindica que foi infringida;<br>
                        3. uma descrição de onde o material que você alega que está infringindo está localizado no site;<br>
                        4. seu endereço, número de telefone e endereço de e-mail;<br>
                        5. uma declaração sua de que você acredita de boa fé que o uso em disputa não está autorizado pelo
                        proprietário dos direitos autorais, seu agente ou a lei;<br>
                        6. uma declaração feita por você, feita sob pena de perjúrio, de que as informações acima descritas
                        no seu Aviso são precisas e que você é proprietário de direitos autorais ou de propriedade
                        intelectual ou está autorizado a agir em nome do proprietário ou dos direitos autorais.</p>

                    <p class="pb-4">Um agente da Camerite para notificação de reclamações de direitos autorais ou outra
                        infração de propriedade intelectual pode ser alcançado da seguinte forma: pelo e-mail
                        <a href="mailto:contato@camerite.com">contato@camerite.com</a>.
                    </p>

                    <b class="cap-text">16. INFORMAÇÕES GERAIS</b><br>
                    <p><b>Total acordo:</b> o <b class="bold-text">Termo de Uso</b> constitui o acordo completo entre você e
                        a Camerite e governa seu
                        uso do <b class="highlight-text">"Serviço"</b>, substituindo quaisquer acordos prévios entre você e
                        a Camerite em relação ao
                        <b class="highlight-text">"Serviço"</b>. Quando a lei local está em conflito com estes <b
                            class="bold-text">Termos de Uso</b> da Camerite, conforme eles se
                        aplicam aos funcionários municipais, e existe um contrato entre a Camerite e a entidade, esse
                        contrato ditará quaisquer exceções aos <b class="bold-text">Termos de Uso</b> conforme se aplicam
                        aos funcionários da
                        entidade. Você também pode estar sujeito a termos e condições adicionais que podem ser aplicados
                        quando você usa ou compra certos outros serviços Camerite, serviços afiliados, conteúdo de terceiros
                        ou software de terceiros.
                    </p>

                    <p><b>Escolha de Lei e Foro:</b> o Termo de <b class="highlight-text">"Serviço"</b> e a relação entre
                        você e a Camerite serão regidos
                        pela legislação brasileira, independentemente das suas disposições de conflito de leis. Você e a
                        Camerite concordam em submeter à jurisdição pessoal e exclusiva do foro da comarca de Joinville-SC
                        para dirimir eventuais conflitos sobre esse instrumento.</p>

                    <p class="pb-4"><b>Renúncia e Divisibilidade dos Termos:</b> a falha na Camerite para exercer ou fazer
                        valer qualquer direito ou provisão dos <b class="bold-text">Termos de Uso</b> não constitui uma
                        renúncia a tal direito ou
                        provisão. Se qualquer disposição do <b class="bold-text">Termo de Uso</b> for considerada por um
                        tribunal de jurisdição
                        competente como inválida, as partes, no entanto, concordam que o tribunal deve esforçar-se para dar
                        cumprimento às intenções das partes, conforme refletido na disposição, e as demais disposições do
                        <b class="bold-text">Termo de Uso</b> permanecem em força total e efeito.
                    </p>

                    <b class="cap-text">17. VIOLAÇÃO</b><br>
                    <p class="pb-4">Informe quaisquer violações dos <b class="bold-text">Termos de Uso</b> ao serviço ao
                        cliente em
                        <a href="mailto:contato@camerite.com">contato@camerite.com</a>.
                    </p>


                    <b class="cap-text">18. DECLARAÇÃO DE POLÍTICA DE SEGURANÇA</b><br>
                    <p>Entendemos que a integridade e estabilidade operacional de nossos serviços é importante para nossos
                        usuários e parceiros. Nosso objetivo é garantir que nossos sistemas sejam protegidos contra uso não
                        autorizado e permaneçam acessíveis 98% (noventa e oito por cento) do tempo.</p>

                    <b>Relatando uma questão de segurança</b><br>
                    <p>Entre em contato conosco em <a href="mailto:seguranca@camerite.com">seguranca@camerite.com</a> se
                        você acredita que identificou um problema ou preocupação de segurança.</p>

                    <b>Centros de dados</b><br>
                    <p>Nossos provedores de centros de dados empregam uma variedade de práticas de segurança física e de
                        sistema. Por favor, veja suas declarações de política para obter detalhes:</p>

                    <p>Microsoft Azure: <a
                            href="https://azure.microsoft.com/pt-br/services/security-center">https://azure.microsoft.com/pt-br/services/security-center</a>
                    </p>

                    <p>Oracle: <a href="https://www.oracle.com/br/trust/">https://www.oracle.com/br/trust/</a>
                    </p>

                    <p>AWS Amazon: <a href="https://aws.amazon.com/pt/security/">https://aws.amazon.com/pt/security/</a>
                    </p>

                    <b>Acesso ao Agente</b><br>
                    <p class="pb-4">O acesso interno a sistemas e serviços é limitado aos funcionários que precisam de
                        acesso para completar seu trabalho e utilizar contas individuais, logins seguros e, em alguns casos,
                        autenticações duplas.</p>

                    <b class="cap-text">19. POLÍTICA DE PRIVACIDADE</b><br>
                    <p>A Política de Privacidade da Camerite pode ser acessada através do seguinte endereço eletrônico
                        <a
                            href="https://camerite.com/politica-de-privacidade">https://www.camerite.com/politica-de-privacidade</a>,
                        parte integrante e complementar ao presente instrumento.
                    </p>
                </div>
            </div>
        </b-container>

        <CameriteFooter />
    </div>
</template>

<script>
import CameriteHeader from "../components/camerite-header/CameriteHeader";
import CameriteFooter from "../components/CameriteFooter";

export default {
    data() {
        return {}
    },
    name: "TalkToUs",
    components: {
        CameriteHeader,
        CameriteFooter
    },
}
</script>

<style lang="scss" scoped></style>
