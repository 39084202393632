<template>
    <div
        class="whatsapp-contact-container position-fixed clickable"
        v-on:click="open('http://wa.me/554792219900')"
    >
        <div class="whatsapp-contact-content animated">
            <div class="talk-to-us">
                <b>Como posso lhe ajudar?</b>
            </div>
            <div class="whatsapp-logo px-1 h3">
                <font-awesome-icon
                    :icon="['fab', 'whatsapp']"
                    :style="{ color: 'white' }"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WhatsAppContact",
    methods: {
        open: function (href) {
            window.open(href, "_blank");
        },
    },
};
</script>

<style scoped lang="scss">
.animated {
    animation-name: pulse;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

div.whatsapp-contact-container {
    bottom: 10px;
    right: 20px;

    div.whatsapp-contact-content {
        height: 65px;
        position: relative;
        text-align: center;
        display: flex;
        flex-wrap: wrap;

        div.talk-to-us {
            margin-right: 20px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background: $camerite-blue;
            padding: 15px;
            border-radius: 10px;
        }

        div.whatsapp-logo {
            width: 65px;
            height: 100%;
            background: #25d366;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.92);
    }

    100% {
        transform: scale(1);
    }
}
</style>
