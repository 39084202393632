import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CollaborativeNeighbourhood from '../views/CollaborativeNeighbourhood.vue'
import Functionalities from '../views/Functionalities.vue'
import CameriteFinder from "../views/CameriteFinder";
import SolvedCrimes from "../views/SolvedCrimes";
import SolvedCrime from "../views/SolvedCrime";
import PoliceSmartCities from "../views/PoliceSmartCities";
import TalkToUs from "../views/TalkToUs";
import Privacy from "../views/Privacy";
import Terms from "../views/Terms";
import NotFound from "../views/404";
import AboutUs from "../views/AboutUs";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/vizinhanca-colaborativa',
        name: 'Colaborative Neighbourhood',
        component: CollaborativeNeighbourhood,
        matchable: true,
        matchingName: 'Vizinhança Colaborativa'
    },
    {
        path: '/vizinhanca-colaborativa/funcionalidades',
        name: 'Functionalities',
        component: Functionalities
    },
    {
        path: '/policia/cidades-inteligentes',
        name: 'Police',
        component: PoliceSmartCities,
        matchable: true,
        matchingName: 'Cidades Inteligentes'
    },
    {
        path: '/vizinhanca-colaborativa/crimes-resolvidos',
        name: 'VC Solved Crimes',
        component: SolvedCrimes
    },
    {
        path: '/policia/crimes-resolvidos',
        name: 'Police Solved Crimes',
        component: SolvedCrimes
    },
    {
        path: '/crimes-resolvidos',
        name: 'Solved Crimes',
        component: SolvedCrimes,
        matchable: true,
        matchingName: 'Crimes resolvidos'
    },
    {
        path: '/vizinhanca-colaborativa/crimes-resolvidos/:id',
        name: 'VC Solved Crime',
        component: SolvedCrime
    },
    {
        path: '/policia/crimes-resolvidos/:id',
        name: 'Police Solved Crime',
        component: SolvedCrime
    },
    {
        path: '/crimes-resolvidos/:id',
        name: 'Solved Crime',
        component: SolvedCrime
    },
    {
        path: '/encontre',
        name: 'Find a Camerite',
        component: CameriteFinder,
        matchable: true,
        matchingName: 'Encontre uma Camerite'
    },
    {
        path: '/fale-conosco',
        name: 'Talk to us',
        component: TalkToUs,
        matchable: true,
        matchingName: 'Entre em contato'
    },
    {
        path: '/politica-de-privacidade',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/termos-de-uso',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/sobre',
        name: 'About us',
        component: AboutUs,
        matchable: true,
        matchingName: 'Sobre nós'
    },
    {
        path: '*',
        name: '404',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (savedPosition) {
                    resolve(savedPosition)
                } else {
                    resolve({ x: 0, y: 0 })
                }
                
            }, 200)
        })
        
    }
})

export default router
