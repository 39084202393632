<template>
  <div>
    <div class="mobile-header py-2">
      <b-container>
        <b-row>
          <div
            class="camerite-logo col-6 d-flex align-items-center"
            v-on:click="$router.push('/').catch(() => {})"
          >
            <b-img fluid src="/landing-page-media/header/logo_pin.png"></b-img>
          </div>

          <div class="col-6 d-flex justify-content-end align-items-center">
            <div
              id="burgerite"
              v-on:click="isBurgerOpen = !isBurgerOpen"
              v-bind:class="{ open: isBurgerOpen }"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>

    <div class="mobile-menu pt-3" v-bind:class="{ open: isBurgerOpen }">
      <div class="py-3" v-for="route in routes" :key="route.path">
        <div class="d-flex justify-content-center">
          <span
            class="inner-menu clickable"
            v-on:click="$router.push(route.path).catch(() => {})"
            >{{ route.name }}</span
          >
        </div>
      </div>

      <div class="py-3 d-flex justify-content-center">
        <span
          class="inner-menu clickable"
          v-on:click="$router.push('/encontre').catch(() => {})"
          >Encontre uma Camerite</span
        >
      </div>

      <div class="py-3 d-flex justify-content-center">
        <span
          class="inner-menu clickable"
          v-on:click="open('https://contato.camerite.com/seja-um-franqueado')"
          >Seja um franqueado</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileHeader",
  props: {
    routes: Array,
  },
  methods: {
    open: function (href) {
      window.open(href, "_blank");
    },
  },
  data() {
    return {
      isBurgerOpen: false,
    };
  },
};
</script>

<style scoped lang="scss">
.clickable {
  &:hover {
    color: $camerite-blue-hover;
  }
}

div.mobile-header {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 2px;
  position: relative;
  background-color: white;
  z-index: 3;
  height: 64px;
  display: flex;
  align-items: center;

  button.bluerite-secondary {
    font-size: 11px;
  }
}

div.mobile-menu {
  position: absolute;
  height: 100vh;
  top: -100vh;
  background-color: white;
  transition: 0.4s;
  width: 100%;
  z-index: 2;
  left: 0;

  &.open {
    top: 48px;
  }
}

#burgerite {
  width: 32px;
  height: 32px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#burgerite span {
  display: block;
  position: absolute;
  height: 4px;
  width: 28px;
  background: $camerite-blue;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

#burgerite span:nth-child(1) {
  top: 5px;
}

#burgerite span:nth-child(2),
#burgerite span:nth-child(3) {
  top: 14px;
}

#burgerite span:nth-child(4) {
  top: 23px;
}

#burgerite.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#burgerite.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#burgerite.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#burgerite.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
</style>
