<template>
    <div class="solved-crime">
        <CameriteHeader/>

        <div class="main-bg">
            <div class="blue-banner"></div>
            <b-container>
                <div class="solved-crime-template offset-lg-1 col-lg-10">
                    <div class="offset-lg-1 col-lg-10">
                        <SC1 v-if="crime === 1"/>
                        <SC2 v-if="crime === 2"/>
                        <SC3 v-if="crime === 3"/>
                        <SC4 v-if="crime === 4"/>
                    </div>
                </div>
            </b-container>

            <b-container class="pt-5">
                <SeeHowEasyItIs/>
            </b-container>
        </div>

        <CameriteFooter/>
    </div>
</template>

<script>
    import CameriteHeader from "../components/camerite-header/CameriteHeader";
    import CameriteFooter from "../components/CameriteFooter";
    import SeeHowEasyItIs from "../components/SeeHowEasyItIs";
    import SC1 from "../templates/SC1";
    import SC2 from "../templates/SC2";
    import SC3 from "../templates/SC3";
    import SC4 from "../templates/SC4";

    export default {
        data() {
            return {
                crime: Number(this.$route.params.id)
            }
        },
        name: "SolvedCrime",
        components: {
            CameriteHeader,
            CameriteFooter,
            SC1,
            SC2,
            SC3,
            SC4,
            SeeHowEasyItIs
        }
    }
</script>

<style scoped lang="scss">
    div.main-bg {
        height: 100%;
        background-color: $camerite-psycho-killer;
        position: relative;
        overflow:hidden;

        div.blue-banner {
            height: 107px;
            width: 100%;
            background-color: $camerite-blue;
            position: absolute;
            z-index: 1;
        }

        div.solved-crime-template {
            z-index: 2;
            position: relative;
            background-color: white;
            margin-top: 75px;
        }
    }
</style>
