<template>
    <div class="solved-crime">
        <h1 class="pt-5 pb-2">Cacoal/RO</h1>
        <p class="support-text pb-4">
            A Vizinhança Colaborativa contribui não apenas para a prevenção como também é um grande recurso para
            resolução de crimes.
        </p>

        <p class="pb-3">
            Cacoal é um bom exemplo disso.
        </p>
        <p class="pb-3">
            Os moradores e a própria polícia local possuem acesso às câmeras de segurança, o que permitiu uma ação bem
            sucedida na região.
            Na ocasião,uma moto tinha sido furtada. Com o auxílio das câmeras de segurança e dos próprios moradores, o
            criminoso foi identificado, localizado e preso. A moto foi recuperada e devolvida ao proprietário.
        </p>
        <p class="pb-4">
            As câmeras de segurança registraram a ação e também a participação de uma moradora. Ela viu o criminoso
            através de seu aplicativo e informou a polícia sobre a direção tomada pelo meliante. Ele havia abandonado a
            moto em um terreno e seguido a pé.
        </p>

        <p class="testimony pb-2">
            “As câmeras ajudaram sim o nosso serviço nessa ocorrência. Inclusive, queria parabenizar o sistema, de muito
            fácil acesso, controle muito intuitivo. Qualquer pessoa com acesso à internet consegue obter imagens
            facilmente pelo celular. É muito fácil.”
        </p>
        <p class="bottom-testimony pb-4">
            Depoimento do policial responsável pela ação
        </p>

        <p class="pb-4">
            Confira abaixo, o vídeo com as imagens da ação policial.
        </p>

        <div class="pb-5">
            <youtube-player id="k833DJkxCM4"></youtube-player>
        </div>
    </div>
</template>

<script>
    import YoutubePlayer from "@/components/YoutubePlayer";
    export default {
        name: "SC3",
        components: {YoutubePlayer}
    }
</script>

<style scoped lang="scss">
    p {
        margin-bottom: unset;
    }

    p.testimony {
        font-size: 18px;
        color: $camerite-blue;
    }

    p.bottom-testimony, span.bottom-testimony {
        font-size: 14px;
        font-weight: bold;
    }
</style>
