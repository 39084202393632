/**
 * Class representing a route for the CameriteHeader component.
 */
export default class Route {
    /**
     * Creates a Route.
     * @param {string} name - The name that will appear on the header component.
     * @param {string} path - The path of the route (e.g. '/about') - must be a valid path on @/router/index.js.
     * @param {array} subRoutes - (Optional) An array of Route objects. If provided, the path will be ignored and
     * the CameriteHeader will show a dropdown element for the routes in the array.
     */
    constructor(name, path, subRoutes) {
        this.name = name;
        this.path = path;
        this.subRoutes = subRoutes;
    }
}
