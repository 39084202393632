<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path id="prefix__play-circle-regular" :fill="color || '#ffffff'"
              d="M19.732 15.419l-5.677-3.452a.776.776 0 0 0-1.152.677v6.71a.776.776 0 0 0 1.152.677l5.677-3.258a.777.777 0 0 0 0-1.354zM24 16a8 8 0 1 0-8 8 8 8 0 0 0 8-8zM9.548 16A6.452 6.452 0 1 1 16 22.452 6.45 6.45 0 0 1 9.548 16z"
              transform="translate(-8 -8)"/>
    </svg>
</template>

<script>
    export default {
        name: 'IconPlay',
        props: {
            color: String
        }
    }
</script>

<style type="text/css" scoped>
    svg {
        width: 16px;
    }
</style>
