<template>
  <div class="colaborative-neighbourhood">
    <CameriteHeader />
    <CameriteJumbotron
      :top="heroTopText"
      :main="heroMainText"
      :bottom="heroBottomText"
      img="/landing-page-media/jumbos/Topo_VC_Principal2.jpg"
      mobile-img="/landing-page-media/jumbos/Img_topo_mobile_VC.png"
      mobile-text-pos="top"
      pos="70%"
    />

    <b-container class="pb-lg-5 pt-4">
      <b-row class="pb-lg-5 pb-3">
        <div class="col-lg-5 py-3">
          <div class="pb-3">
            <h2>Insegurança, você não está sozinho</h2>
          </div>

          <div class="pb-3">
            <span
              >70% dos brasileiros estão tomando algum tipo de medida restritiva
              por conta da insegurança.</span
            >
          </div>

          <div>
            <b-row class="px-3 pb-3">
              <div class="pr-2">
                <IconThumbsDown />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Evitam sair à noite</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-3">
              <div class="pr-2">
                <IconThumbsDown />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span
                  >Evitam que os filhos brinquem na rua ou visitem amigos</span
                >
              </div>
            </b-row>

            <b-row class="px-3 pb-3">
              <div class="pr-2">
                <IconThumbsDown />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Mudam o trajeto para o trabalho</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-3">
              <div class="pr-2">
                <IconThumbsDown />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Mudam de endereço</span>
              </div>
            </b-row>
          </div>
        </div>

        <div class="col-lg-2 separator">
          <VerticalSeparator :width="1" :center="true" />
        </div>

        <div class="col-lg-5 py-3">
          <div class="pb-3">
            <h2>Isolamento não é o caminho</h2>
          </div>

          <div class="pb-3">
            <span
              >Os serviços tradicionais oferecidos pelo mercado de segurança não
              trabalham de forma preventiva, mas são úteis apenas depois da ação
              de criminosos, além de isolar o morador tornando sua vida ainda
              mais restritiva.</span
            >
          </div>

          <div>
            <b-row class="px-3 pb-3">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Muros</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-3">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Cercas elétricas</span>
              </div>
            </b-row>

            <b-row class="px-3 pb-3">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Alarmes</span>
              </div>
            </b-row>

            <b-row class="px-3">
              <div class="pr-2">
                <IconAttention />
              </div>

              <div class="col-10 d-inline-block px-0">
                <span>Câmeras de segurança internas</span>
              </div>
            </b-row>
          </div>
        </div>
      </b-row>

      <div class="col-lg-10 offset-lg-1 pt-4 pt-md-5 text-center">
        <div class="pb-lg-5 pb-3">
          <b-img
            width="266"
            height="80"
            src="/landing-page-media/collaborative-neighborhood/Camada2%402x.png"
          ></b-img>
        </div>

        <div class="pb-1">
          <h2 class="text-center">+ Colaboratividade + Segurança</h2>
        </div>

        <div class="pb-3">
          <span
            >Com a Vizinhança Colaborativa Camerite, você e seus vizinhos
            compartilham as imagens de um único sistema de câmeras de
            monitoramento, agindo de forma preventiva e tornando sua rua mais
            segura.</span
          >
        </div>
      </div>

      <div class="pt-4 pb-5">
        <h3 class="text-center">
          Mas... como funciona a Vizinhança Colaborativa?
        </h3>
      </div>

      <b-row class="pb-5">
        <div
          class="d-flex flex-column align-items-center col-12 col-sm-4 pb-4 pb-sm-0"
        >
          <div class="pb-2">
            <IconInternet />
          </div>
          <span class="text-center"
            >Um morador será responsável por disponibilizar o sinal de internet
            da sua residência.</span
          >
        </div>

        <div
          class="d-flex flex-column align-items-center col-12 col-sm-4 pb-4 pb-sm-0"
        >
          <div class="pb-2">
            <IconCamera />
          </div>
          <span class="text-center"
            >Nós instalamos uma câmera para você e posicionamos de modo que
            privilegie monitore duas ou mais casas da rua através de acesso
            compartilhado.</span
          >
        </div>

        <div
          class="d-flex flex-column align-items-center col-12 col-sm-4 pb-4 pb-sm-0"
        >
          <div class="pb-2">
            <IconSmartphone />
          </div>

          <span class="text-center"
            >Através de uma mensalidade, serão distribuídos login e senha para
            que você e seus vizinhos tenham acesso às imagens ao vivo 24 horas 7
            dias da semana de maneira fácil!</span
          >
        </div>
      </b-row>

      <div class="pt-3 pb-4">
        <youtube-player id="Lwsu_obtBL0"></youtube-player>
      </div>

      <div class="py-5">
        <h2 class="text-center pb-4">Com o aplicativo Camerite você tem...</h2>

        <b-row align-v="center">
          <div class="col-lg-5">
            <b-carousel
              class="pb-4"
              v-model="cnCarouselSlide"
              ref="cnCarousel"
              :interval="0"
            >
              <div class="carousel-item active">
                <div class="pb-4">
                  <h3>Acesso fácil a qualquer hora</h3>
                </div>

                <div>
                  <span
                    >Você e seus vizinhos poderão acessar e ver através de suas
                    câmeras de segurança o que está acontecendo na rua em tempo
                    real, de forma fácil e rápida, a qualquer hora e de qualquer
                    lugar, cooperando para a maior proteção de suas famílias e
                    propriedades.</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Gravações dos últimos 7 dias</h3>
                </div>

                <div>
                  <span
                    >Avance o volte nas gravações das câmeras de segurança
                    através da linha do tempo, que mantém as imagens dos últimos
                    7 dias.</span
                  >
                </div>
              </div>

              <div class="carousel-item">
                <div class="pb-4">
                  <h3>Botão de alerta para situações suspeitas</h3>
                </div>

                <div>
                  <span
                    >Envie e receba notificações de situações suspeitas através
                    de um botão exclusivo no aplicativo. Assim, você e seus
                    vizinhos previnem a ação de criminosos em sua rua.</span
                  >
                </div>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="!mobileView">
              <ArrowButton
                class="mx-2"
                direction="left"
                :action="previousSlide"
              ></ArrowButton>
              <ArrowButton
                class="mx-2"
                direction="right"
                :action="nextSlide"
              ></ArrowButton>
            </div>
          </div>

          <div class="col-lg-5 offset-lg-1">
            <b-carousel
              class="pb-4"
              v-model="cnCarouselSlideImg"
              ref="cnCarouselImg"
              :interval="0"
            >
              <div class="carousel-item active">
                <b-img
                  src="/landing-page-media/collaborative-neighborhood/App_Android_lista_cam.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item">
                <b-img
                  src="/landing-page-media/collaborative-neighborhood/App_Android_timeline2.png"
                  fluid
                ></b-img>
              </div>
              <div class="carousel-item">
                <b-img
                  src="/landing-page-media/collaborative-neighborhood/App_Android_alerta2.png"
                  fluid
                ></b-img>
              </div>
            </b-carousel>

            <div class="d-flex justify-content-center" v-if="mobileView">
              <ArrowButton
                class="mx-2"
                direction="left"
                :action="previousSlide"
              ></ArrowButton>
              <ArrowButton
                class="mx-2"
                direction="right"
                :action="nextSlide"
              ></ArrowButton>
            </div>
          </div>
        </b-row>
      </div>

      <div class="py-5">
        <div class="pb-5">
          <h2 class="text-center">Depoimentos de clientes Camerite</h2>
        </div>

        <div class="col-12">
          <b-row class="d-flex justify-content-center">
            <div class="col-lg-3 px-lg-0 pb-5">
              <div>
                <p class="testimony">
                  “A rua aqui era bastante intranquila. Agora com as câmeras de
                  segurança estamos bem mais tranquilos. O próprio comportamento
                  dos moradores mudou, nós nos sentimos mais protegidos.”
                </p>
              </div>

              <div>
                <span class="support-title">Maria Helena</span>
              </div>
            </div>

            <div class="col-lg-3 offset-lg-1 px-lg-0 pb-5">
              <div>
                <p class="testimony">
                  “Antes dessas câmeras de segurança as pessoas andavam aflitas.
                  Agora que colocaram esse sistema de segurança o as pessoas têm
                  mais segurança e liberdade para ficar no ponto de ônibus e
                  levar os filhos para a escola.”
                </p>
              </div>

              <div>
                <span class="support-title">Jonacir Fontana</span>
              </div>
            </div>

            <div class="col-lg-3 offset-lg-1 px-lg-0 pb-5">
              <div>
                <p class="testimony">
                  “Antes da Camerite, a situação da rua era vulnerável. As
                  pessoas ficavam com medo de estranhos na rua. Agora, as
                  próprias câmeras de segurança ajudam a intimidar, além de
                  estarmos todos ligados em movimentações suspeitas.”
                </p>
              </div>

              <div>
                <span class="support-title">Sérgio Fausti</span>
              </div>
            </div>
          </b-row>
        </div>
      </div>

      <SeeHowEasyItIs />
    </b-container>

    <CameriteFooter />
  </div>
</template>

<script>
import ArrowButton from "../components/ArrowButton";
import CameriteHeader from "../components/camerite-header/CameriteHeader.vue";
import CameriteJumbotron from "../components/CameriteJumbotron";
import VerticalSeparator from "../components/VerticalSeparator";
import IconThumbsDown from "../components/icons/IconThumbsDown";
import IconAttention from "../components/icons/IconAttention";
import IconCamera from "../components/icons/IconCamera";
import IconSmartphone from "../components/icons/IconSmartphone";
import IconInternet from "../components/icons/IconInternet";
import SeeHowEasyItIs from "../components/SeeHowEasyItIs";
import CameriteFooter from "../components/CameriteFooter";
import YoutubePlayer from "../components/YoutubePlayer";

export default {
  name: "CollaborativeNeighbourhood",
  components: {
    ArrowButton,
    CameriteHeader,
    CameriteJumbotron,
    VerticalSeparator,
    IconThumbsDown,
    IconAttention,
    IconCamera,
    IconSmartphone,
    IconInternet,
    SeeHowEasyItIs,
    CameriteFooter,
    YoutubePlayer,
  },
  data() {
    return {
      heroTopText: "Vizinhança Colaborativa",
      heroMainText: "Câmeras de segurança em sua rua para você e seus vizinhos",
      heroBottomText:
        "O que você prefere? Viver em uma casa protegida ou em uma sociedade mais segura?",
      cnCarouselSlide: 0,
      cnCarouselSlideImg: 0,
      mobileView: window.innerWidth <= 991,
    };
  },
  methods: {
    previousSlide() {
      this.$refs.cnCarousel.prev();
      this.$refs.cnCarouselImg.prev();
    },
    nextSlide() {
      this.$refs.cnCarousel.next();
      this.$refs.cnCarouselImg.next();
    },
    handleView: function () {
      this.mobileView = window.innerWidth <= 991;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleView);
  },
};
</script>
