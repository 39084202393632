<template>
    <div class="offset-lg-2 col-lg-8">
        <youtube :player-width="playerWidth" :player-height="playerHeight" :video-id="id"></youtube>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                playerWidth: '640',
                playerHeight: '360'
            }
        },
        name: "YoutubePlayer",
        props: {
            id: String
        },
        methods: {
            handleResize() {
                this.playerWidth = window.innerWidth <= 485 ? '320' : '640';
                this.playerHeight = window.innerWidth <= 485 ? '240' : '360';
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        }
    }
</script>

<style>
    iframe {
        width: 100%;
    }

</style>
