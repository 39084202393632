<template>
  <div class="find-camerite">
    <CameriteHeader />
    Redirecionando...
    <CameriteFooter />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "CameriteFinder",
  components: {},
  methods: {},
  mounted() {
    window.location.href =
      "https://wa.me/5547992219900?text=Ol%C3%A1,%20gostaria%20de%20saber%20se%20h%C3%A1%20um%20franqueado%20em%20minha%20cidade.";
  },
};
</script>
